import { useEffect, useRef, useState } from "react"
import { backendAccessGet, backendAccessPost, backendMe } from "../../Utils/Authentication"
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'

import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { DarkButton } from '../SpiceUI/MUI'

import Checkmark from "../SpiceUI/Checkmark"
import { TiPlus } from "react-icons/ti";

import { FaRegHeart } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { BiSolidEdit } from "react-icons/bi";
import { MdEdit } from "react-icons/md";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { IoIosRemoveCircle } from "react-icons/io";
import { ImCross } from "react-icons/im";
import { RxCross1 } from "react-icons/rx";
import { IoClose } from "react-icons/io5";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from '@mui/material/Tooltip';
import { ControlButton, WhiteButton } from "../SpiceUI/MUI"

export function SelectedWidgetItem(props) {
    const item = props.item
    const setWidgetIdToEdit = props.setWidgetIdToEdit
    const setSelected = props.setSelected
    const snapshots = props.snapshots

    const [ isHover, setIsHover ] = useState( false )
    const [ editHover, setEditHover ] = useState( false )
    const [ removeHover, setRemoveHover ] = useState( false )

    const deselect = () => {
        let d = {}
        d.widget_uid = item.widget_id 
        d.selected = 0 

        backendAccessPost('/api/widgets/update-selected/', d ).then( res => {
            if( res != null )
            {
                setSelected( res.selected )
            }
        })
    }

    return (
        <div style={{ width: '100%', height:'80px', marginBottom:'0px', padding:'5px' }}
             onMouseEnter={ e => setIsHover(true) }
             onMouseLeave={ e => setIsHover(false) }
        >
            <div className="square border-1" 
                 style={{ width: '100px', 
                          height: '70px', 
                          border: '0.2px solid #F5F5F5', 
                          borderRadius: '2px', float:'left',
                        }}
            >
                <img style={{
                    margin: 'auto',
                    textAlign: 'center',
                    width:'90%' }} 
                    src={"data:image/png;base64, " + snapshots[item.widget_id]} 
                />
            </div>
            <div style={{ width: 'calc( 100% - 100px )',textAlign:'left',paddingLeft:'10px', paddingTop:'5px', 
                          height:80, float:'right'}}>
                <div style={{ fontSize:10 }}><b>{item.widget_name}</b> </div>
                <div style={{ fontSize:10 }}>Designed by <b>{item.owner}</b> </div>
                <div className="d-flex justify-content-end" style={{ marginTop:'5px', marginTop:'15px'}}>
                        <Button style={{ textTransform:'none', fontSize: 12, width:'50%', boxShadow: 'none',
                                         marginRight:'5px', height:'20px', borderRadius:'2px'}} 
                                size="small"
                                onClick={ e => setWidgetIdToEdit(item.widget_id) }
                                variant="contained"
                        >
                            Edit
                        </Button>
                        <DarkButton style={{ textTransform:'none', fontSize: 12, width:'50%', boxShadow: 'none',
                                             height:'20px', borderRadius:'2px'}} 
                                    size="small"
                                    onClick={e => deselect(item.widget_id)}
                        >
                            Remove
                        </DarkButton>                    
                </div>
            </div>
        </div>
    )
}

export function FavoriteHeart( props )
{
    const favorite = props.favorite 
    const color = props.color 

    if( favorite == 0 )
    {
        return <FaRegHeart size={14} style={{ cursor:'pointer', color:color }} />
    }
    else 
    {
        return <FaHeart size={14} style={{ cursor:'pointer', color:color }} />
    }
}

export function WidgetBoxControl( props ) {
    const data = props.data
    const favorite = props.favorite 
    const toggleFavorite = props.toggleFavorite
    const edit = props.edit 
    const remove = props.remove
    const hasRemove = props.hasRemove
    
    const [ isFavoriteHover, setIsFavoriteHover ] = useState(false)
    const [ isEditHover, setIsEditHover ] = useState(false)
    const [ isCloseHover, setIsCloseHover ] = useState(false)

    return (
        <ButtonGroup style={{ height:'20px', maxWidth:'60px' }} 
                     size="small" variant="outline">
            <ControlButton size="small" 
                           style={{ minHeight:'20px', maxHeight:'20px', 
                                    maxWidth:'20px', minWidth:'20px' }}
                           onClick={toggleFavorite}
            >
                <FavoriteHeart favorite={favorite} />
            </ControlButton>
            <ControlButton size="small" 
                           style={{ minHeight:'20px', maxHeight:'20px', 
                                    maxWidth:'20px', minWidth:'20px' }}
                           onClick={edit}
                >
                <MdEdit  />
            </ControlButton>
            { hasRemove &&
            <ControlButton size="small" 
                           style={{ minHeight:'20px', maxHeight:'20px', 
                                    maxWidth:'20px', minWidth:'20px' }}
                           onClick={remove}
            >
                <IoClose />
            </ControlButton>
            }
        </ButtonGroup>
    )
}

export function WidgetBox( props ) {
    const data = props.data 
    const snapshots = props.snapshots 
    const updateWidgets = props.updateWidgets
    const setSelected = props.setSelected
    const setWidgetIdToEdit = props.setWidgetIdToEdit
    const removeWidget = props.removeWidget

    const [ favorite, setFavorite ] = useState(data.favorite)

    const [ isHover, setIsHover ] = useState(false)
    const [ isSelectHover, setIsSelectHover ] = useState(false)

    const toggleFavorite = () => {
        let new_favorite = 1-favorite

        let d = {}
        d.widget_uid = data.widget_id 
        d.favorite = new_favorite 

        backendAccessPost('/api/widgets/update-favorite/', d ).then( res => {
            if( res != null )
            {
                setFavorite( new_favorite )

                updateWidgets( res.widgets, res.favorites )
            }
        })
    }

    const selectWidget = () => {
        let d = {}
        d.widget_uid = data.widget_id 
        d.selected = 1

        backendAccessPost('/api/widgets/update-selected/', d ).then( res => {
            if( res != null )
            {
                setSelected( res.selected )
            }
        })
    }

    useEffect( () => {
        setFavorite( data.favorite )
    },[data])
    
    return (
        <div style={{ minWidth:'190px', height:'167px', marginRight:'10px'}}
             onMouseEnter={e => setIsHover(true)}
             onMouseLeave={e => setIsHover(false)} >
            <div style={{ height: '167px', width: '100%',
                          border: (isHover) ? '0.2px solid #DADADA':'',
                          boxShadow:(isHover) ? '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.05)': '',
                        }}>
                <div style={{ width:'100%', height:'123px',
                              border: '0.2px solid #F5F5F5',
                              position: 'relative', alignItems:'center'
                            }}> 
                    <div className="d-flex justify-content-center align-items-center"
                         style={{ height:'100%', width:'100%', position:'absolute', top:'0px', left:'0px'}}>
                        <img style={{ width: '90%' }} 
                             src={"data:image/png;base64, " + snapshots[data.widget_id]} 
                        />
                    </div>
                    <div className="d-flex justify-content-end align-items-start"
                         style={{ height:'100%', width:'100%', position:'absolute', top:'0px', left:'0px', zIndex:1, padding:'10px'}}>
                        { isHover &&
                        <WidgetBoxControl favorite={favorite} toggleFavorite={toggleFavorite}
                                          edit={ e => setWidgetIdToEdit(data.widget_id)}
                                          remove={ e => removeWidget(data)}
                                          hasRemove = { removeWidget != null }
                        />
                    }
                    </div>
                </div>
                <div style={{ width:'100%', height:'calc(100% - 123px)', padding:'5px' }}>
                    <div className="d-flex justify-content-center align-items-start"
                         style={{ height:'100%', width:'27px', float:'left'}}>
                        
                        <WhiteButton style={{ maxWidth: '22px', minWidth:'22px', maxHeight: '22px', minHeight:'22px', 
                                              borderRadius:'0px', padding:'2px', 
                                              border: '0.5px solid #DADADA' }}
                                     onClick={selectWidget}
                        >
                            <TiPlus size={16}  />
                        </WhiteButton>
                        <div style={{ float: 'left', marginLeft: '5px', textAlign:'left' }}>
                        </div>
                    </div>
                    <div style={{ height:'100%', width:'calc(100% - 27px)', float:'right', 
                                  fontSize:'8px', textAlign:'left' }}>
                        <div style={{ width:'100%', fontWeight:500}}>
                            {data.widget_name}
                        </div>
                        <div style={{ width:'100%', overflow:'hidden' }}>
                            <span style={{ fontWeight: 200 }}>Designed by </span>
                            <span style={{ fontWeight: 500, paddingLeft: 2, wordWrap:"break-word"}}>{data.owner}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function WidgetsRow( props ) {
    const rowName = props.rowName 
    const widgets = props.widgets 
    const snapshots = props.snapshots
    const updateWidgets = props.updateWidgets
    const setSelected = props.setSelected
    const setWidgetIdToEdit = props.setWidgetIdToEdit
    const removeWidget = props.removeWidget

    return (
        <div style={{ width:'100%', marginBottom:'15px'}}>
            <div className="d-flex justify-content-left align-items-center" 
                 style={{ width:'100%', height:'20px', paddingLeft:'15px', fontSize:14 }}>
                {rowName} widgets  
            </div>
            <div style={{ width:'100%', height:'200px', padding:'15px', overflowX:'scroll', display:'flex',
                          flexDirection:'row'}}>
                { widgets &&
                    <>
                    { widgets.map( (widget,idx) => 
                        <WidgetBox data={widget} snapshots={snapshots} updateWidgets={updateWidgets}
                                   setSelected={setSelected} setWidgetIdToEdit={setWidgetIdToEdit} 
                                   removeWidget={removeWidget} key={idx} />
                    )}
                    </>
                }
            </div> 
        </div>
    )
}

export function SelectedWidgetsBar( props ) {

    const selected = props.selected 
    const setSelected = props.setSelected
    const snapshots = props.snapshots 
    const setWidgetIdToEdit = props.setWidgetIdToEdit
    const maxWidgets = props.maxWidgets

    return (
        <div style={{ width:'100%', height:'100%'}}>
            <div className="d-flex align-items-center" style={{ height: '30px', width: '100%', marginBottom:'5px' }}>        
                <div style={{ float:'left', fontSize:14 }}>
                    Selected widgets ({selected.length}/{maxWidgets})
                </div>
                <Tooltip title="Selected widgets are synced to the Spice.Stream application. Upgrade your account to 
                                            increase the number of selected widgets."
                         arrow
                >
                    <div style={{ float:'right', marginLeft:'5px' }}>
                        <HiOutlineExclamationCircle />
                    </div>

                </Tooltip>
            </div>
            <div style={{ height:'calc(100% - 30px)', overflowY:'scroll' }}>
                    { selected.map( (item,idx) => 
                        <SelectedWidgetItem item={item} snapshots={snapshots} setSelected={setSelected} 
                                            setWidgetIdToEdit={setWidgetIdToEdit}/>
                    )}
            </div>

        </div>
    )
}

export default function WidgetBrowser(props) {
    const setWidgetIdToEdit = props.setWidgetIdToEdit
    const userConfig = props.userConfig

    const [userWidgets, setUserWidgets] = useState({})
    const [snapshots, setSnapshots] = useState({})

    const [ selected, setSelected ] = useState([])
    const [ favorites, setFavorites ] = useState([])

    const [ showFavorites, setShowFavorites ] = useState( false )

    const [ maxWidgets, setMaxWidgets ] = useState( userConfig.config.max_widgets )

    const reload = () => {
        backendAccessGet('/api/widgets/user-widgets/').then(res => {
            if (!res) {
                return
            }

            setSnapshots(res.snapshots)
            setSelected(res.selected)

            updateWidgets( res.widgets, res.favorites )
        })
    }

    useEffect(() => {
        reload()
        setWidgetIdToEdit(null)
    }, [])

    const updateWidgets = ( widgets, favorites ) => {
        if( widgets )
        {
            setUserWidgets( widgets )
        }

        if( favorites )
        {
            setFavorites( favorites )
        }
    }

    const removeWidget = (widget_data) => {
        if( !window.confirm(`Are you sure you want to remove widget ${widget_data.widget_name}?`) )
        {
            return
        }


        const widget_id = widget_data.widget_id
        let data = {}
        data['widget_uid'] = widget_id

        backendAccessPost('/api/widgets/remove/', data).then(res => {
            if (res == null) {
                return
            }

            setSelected(res.selected)
            updateWidgets( res.widgets, res.favorites )
        })
    }

    const updateSelected = (uid, selected) => {
        let data = {}
        data['widget_uid'] = uid
        data['selected'] = selected

        backendAccessPost('/api/widgets/update-selected/', data).then(res => {
            if (res == null) {
                return
            }

            setUserWidgets(res.widgets)
        })
    }

    useEffect( () => {

        if( favorites.length > 0 )
        {
            setShowFavorites( true )
        }
        else 
        {
            setShowFavorites( false )
        }
    }, [ favorites ] )

    useEffect( () => {
        setMaxWidgets(userConfig.config.max_widgets)
    }, [userConfig] )

    return (
        <Container style={{ maxWidth:'1400px', height:'100%', paddingLeft:'50px', paddingRight:'50px'}}>
            <div className="d-flex justify-content-start"
                 style={{ height: '100%', width: '300px', float:'left' }}>
                <div style={{width:'250px', height:'calc(100% - 25px)', marginTop:'0px' }}>
                    <SelectedWidgetsBar selected={selected} setSelected={setSelected} 
                                        snapshots={snapshots} 
                                        maxWidgets={maxWidgets}
                                        setWidgetIdToEdit={setWidgetIdToEdit}
                    />
                </div>
            </div>
            <div style={{ height: '100%', width: 'calc(100% - 300px)', overflowY:'scroll', float:'right' }}>
                { showFavorites && 
                <WidgetsRow rowName="Favorit" widgets={favorites} snapshots={snapshots} 
                            updateWidgets={updateWidgets} setSelected={setSelected}
                            setWidgetIdToEdit={setWidgetIdToEdit}
                />
                }
                { Object.entries(userWidgets).map( (item,idx) => 
                <WidgetsRow rowName={item[0] + "'s"} widgets={item[1]} snapshots={snapshots} 
                            updateWidgets={updateWidgets} setSelected={setSelected} 
                            setWidgetIdToEdit={setWidgetIdToEdit}
                            removeWidget={removeWidget}
                />
                )}
                <WidgetsRow rowName="Spicy"/>
            </div>
        </Container>
    )
}


