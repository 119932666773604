import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { grey } from '@mui/material/colors'

export const DarkButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#000000'),
    backgroundColor:'#000000',
    '&:hover': {
        backgroundColor: '#282828',
    },
}));

export const GreenButton = styled(Button)(({ theme }) => ({
    color: '#FFFFFF',
    backgroundColor:'#78A49B',
    '&:hover': {
        backgroundColor: '#78A49B',
    },
}));

export const GreyButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[700]),
    backgroundColor:grey[700],
    '&:hover': {
        backgroundColor: grey[500],
    },
}));

export const ControlButton = styled(Button)(({ theme }) => ({
    color: '#000000',
    backgroundColor:'#D9D9D9AC',
    boxShadow:'none',
    textTransform:'none',
    borderRadius:'2px',
    padding:'0px',
    '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#000000',
    },
}));

export const WhiteButton = styled(Button)(({ theme }) => ({
    color: '#000000',
    backgroundColor:'#FFFFFF',
    boxShadow:'none',
    textTransform:'none',
    '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#000000',
    },
}));


