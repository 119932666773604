import { useEffect, useState, useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from '@mui/material/Button'


import { widgetCapture } from "../Misc/WidgetCapture";

export function SaveModalBody( props )
{
    const closeModal = props.closeModal
    const saveCloseModal = props.saveCloseModal

    const name = props.name 

    const layers = props.layers 
    const images = props.images
    const layersCfg = props.layersCfg

    const captureRef = useRef(null)
    
    const [ nameLocal, setNameLocal ] = useState( name.current )
    const [ nameError, setNameError ] = useState( false )

    const [ snapshot, setSnapshot ] = useState(null)

    useEffect( () => {
        widgetCapture( 366, 246, layers, images, layersCfg ).then( pngData => {
            setSnapshot( pngData )
        })
    },[])

    const save = () => {
        if( snapshot == null )
        {
            return
        }

        if( nameLocal == "" ) {
            setNameError( true )
            return
        }
        setNameError( false )

        name.current = nameLocal
        saveCloseModal( nameLocal, snapshot )
    }
    
    return (
        <>
        <Modal.Body>
            <div className="d-flex justify-content-center" style={{ height:'246' }}>
                <img style={{ border:'1px solid'}} src={"data:image/png;base64, " + snapshot}/> 
            </div>
            <div style={{ marginBottom:'5px'}}>
                <b>Widget Name</b>
            </div>
            <div>
                <Form.Control value={nameLocal} onChange={ e => setNameLocal(e.target.value) }
                              isInvalid={nameError}/>
            </div>
        </Modal.Body>
        <Modal.Footer style={{ border:'none' }}>
            <Button variant="contained" size="sm" style={{ marginRight:'5px', textTransform:'none' }} onClick={closeModal}>Close</Button>
            <Button variant="contained" size="sm" style={{ textTransform:'none' }} onClick={save}>Save</Button>
        </Modal.Footer>
        </>
    )
}

export default function SaveModal( props )
{
    const showModal = props.showModal 
    const closeModal = props.closeModal
    const saveCloseModal = props.saveCloseModal

    const name = props.name 
    const setName = props.setName 
    const nameError = props.nameError

    const layers = props.layers 
    const images = props.images
    const layersCfg = props.layersCfg

    return (
        <Modal show={showModal} onHide={closeModal}>
            <Modal.Header closeButton style={{ border:'none' }}>
                <Modal.Title>Save</Modal.Title>
            </Modal.Header>
            { showModal &&
            <SaveModalBody closeModal={closeModal} saveCloseModal={saveCloseModal} 
                           name={name} setName={setName} nameError={nameError}
                           layers={layers} images={images} layersCfg={layersCfg}/>

            }
        </Modal>
    )
}
