import { useEffect, useState, useRef } from "react";

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'

import EffectItem from './Effects/EffectItem'
import ShakerEffect from './Effects/ShakerEffect'
import ScaleEffect from "./Effects/ScaleEffect";
import RotationEffect from './Effects/RotationEffect'
import SuperEmotEffect from './Effects/SuperEmotEffect'
import StreamerAvatarEffect from './Effects/StreamerAvatarEffect'
import ChatAvatarEffect from './Effects/ChatAvatarEffect'
import AvatarEffect from './Effects/AvatarEffect'
import EmoticonEffect from './Effects/EmoticonEffect'
import TextEffect from './Effects/TextEffect'

import { stringToHash } from '../Misc/FileTools';

export function Title( props )
{
    const text = props.text 

    return (
        <div className="d-flex" style={{ height:'30px', width:'100%',textAlign:'left'}}>
            { text }
        </div>
    )
}

export default function Properties( props )
{
    const layers = props.layers 
    const setLayers = props.setLayers 
    const layersCfg = props.layersCfg

    const selected = props.selected 
    const selectedIdx = props.selectedIdx

    const modalOpen = props.modalOpen

    const accountType = props.accountType

    const [ type, setType ] = useState("")

    useEffect( () => {
        if( !layersCfg.current[selected] )
        {
            setType("")
            return
        }

        setType( layersCfg.current[selected].type )
    },[selected])

    if( type == "image" )
    {
        return (
            <div style={{ width:'100%', height:'100%', padding:'5px', overflowY:'scroll',textAlign:'left',backgroundColor:'##F1F1F1' }}>
            { selectedIdx >= 0 &&
                <>
                <ShakerEffect layers={layers} setLayers={setLayers} layersCfg={layersCfg} 
                              selected={selected} selectedIdx={selectedIdx} />
                <RotationEffect layers={layers} setLayers={setLayers} layersCfg={layersCfg} 
                                selected={selected} selectedIdx={selectedIdx} />
                <ScaleEffect layers={layers} setLayers={setLayers} layersCfg={layersCfg}
                             selected={selected} selectedIdx={selectedIdx}
                />
                <AvatarEffect layers={layers} setLayers={setLayers} layersCfg={layersCfg}
                              selected={selected} selectedIdx={selectedIdx} modalOpen={modalOpen} 
                              canPlace={true} disabled={accountType == 'mild'}
                /> 
                <EmoticonEffect layers={layers} setLayers={setLayers} layersCfg={layersCfg}
                                selected={selected} selectedIdx={selectedIdx} modalOpen={modalOpen} 
                                canPlace={true} disabled={accountType == 'mild'}
                /> 
                <TextEffect layers={layers} setLayers={setLayers} layersCfg={layersCfg}
                                selected={selected} selectedIdx={selectedIdx} modalOpen={modalOpen} 
                                canPlace={true} disabled={accountType == 'mild'}
                /> 

                </>
            }
            </div>
        )
    }
    else if( type == "avatar" )
    {
        return (
            <div style={{ width:'100%', height:'100%', padding:'5px', overflowY:'scroll',textAlign:'left',backgroundColor:'##F1F1F1' }}>
            { selectedIdx >= 0 &&
                <AvatarEffect layers={layers} setLayers={setLayers} layersCfg={layersCfg}
                              selected={selected} selectedIdx={selectedIdx} modalOpen={modalOpen} canPlace={false}
                              disabled={accountType == 'mild'}
                />
            }
            </div>
        )
    }
    else if( type == "emoticon" )
    {
        return (
            <div style={{ width:'100%', height:'100%', padding:'5px', overflowY:'scroll',textAlign:'left',backgroundColor:'##F1F1F1' }}>
            { selectedIdx >= 0 &&
                <EmoticonEffect layers={layers} setLayers={setLayers} layersCfg={layersCfg}
                                selected={selected} selectedIdx={selectedIdx} modalOpen={modalOpen} 
                                canPlace={false} disabled={accountType == 'mild'}
                /> 
            }
            </div>
        )
    }
    else if( type == "text" )
    {
        return (
            <div style={{ width:'100%', height:'100%', padding:'5px', overflowY:'scroll',textAlign:'left',backgroundColor:'##F1F1F1' }}>
            { selectedIdx >= 0 &&
                <TextEffect layers={layers} setLayers={setLayers} layersCfg={layersCfg}
                                selected={selected} selectedIdx={selectedIdx} modalOpen={modalOpen} 
                                canPlace={false} disabled={accountType == 'mild'}
                /> 
            }
            </div>
        )

    }
}


