import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'

import ScaledImg from '../ScaledImg'

import { FaWindows } from "react-icons/fa";
import { FaTwitch } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";

export function ActionBox( props )
{
    const title = props.title
    const onClick = props.onClick

    const [ isHover, setIsHover ] = useState( false )

    return (
        <div style={{ width:'100%', padding:'10px', marginBottom:'50px' }}
             onMouseEnter={e => setIsHover(true)}
             onMouseLeave={e => setIsHover(false)}
        >
            { isHover &&
                <> {title}
                </>
            }
            <div style={{ width:'100%', padding:'10px', borderRadius: '10px',
                          backgroundColor: (isHover) ? '#F8F8F8': '',
                          cursor:(onClick) ? 'pointer' : ''}}
                 onClick={onClick}
            >
                {props.children}
            </div>
        </div>
    )
}

export function Downloads( props )
{
    return (
        <div style={{ width:'100%', height:'113px' }} >
            <div style={{ width:'100%', marginBottom:'10px' }}>
                <div>
                    Spice.Stream (v 1.003-beta)
                </div>
                <div>
                    <FaWindows style={{ color:'#0d6efd', cursor:'pointer'}} />
                </div>
            </div>
            <div style={{ width:'100%' }}>
                <div>
                    OBS Plugins (v 1.003-beta) 
                </div>
                <div>
                    <FaWindows style={{ color:'#0d6efd', cursor:'pointer'}} />
                </div>
            </div>
        </div>
    )
}

export function InternetAccounts( props )
{
    const accounts = props.accounts

    const [ twitchColor, setTwitchColor ] = useState("#D7D7D7")

    useEffect( () => {
        if( accounts.twitch )
        {
            setTwitchColor('#6441a5')
        }
    },[])

    return (
        <>
            <FaTwitch style={{ marginRight: 15, fontSize:'20px', color:twitchColor }} />
            <FaYoutube style={{ marginRight: 15, fontSize:'20px', color:'#D7D7D7'}} />
            <FaFacebook style={{ marginRight:15, fontSize:'20px', color:'#D7D7D7'}} />
            <FaTiktok style={{ marginRight:15, fontSize:'20px', color:'#D7D7D7'}} />
            <FaXTwitter style={{ fontSize:'20px', color:'#D7D7D7'}} />
        </>
    )
}

export function MiddleComponents(props) {
    return (
        <div className="fw-light"style={{ width: '500px', marginBottom:'30px', fontSize:'14px' }}>
            <div style={{ marginBottom:'5px'}}>
                Welcome to Spice.Stream's early access!!
            </div>
            <div>
            We are glad that you are here to test our interactive widget builder. Both as a streamer and a viewer,
            you can get access to permimum features during our beta test by earning SPCs. You can also support us by
            subscribing to our service.
            </div>
        </div>
    )
}

export default function Profile( props )
{
    //const navigate = useNavigate()

    const me = props.me 
    const avatarImage = props.avatarImage
    const setState = props.setState
    const accounts = props.accounts

    useEffect( () => {
        setState("")
    },[])

    const openDownloads = () => {
        setState("6")
        //navigate('/downloads')
    }

    const openInternetAccounts = () => {
        setState("5")
        //navigate('/internet-accounts')
    }


    return (
        <Container style={{height:'100%', maxWidth:'1200px'}}>
            <div className="d-flex justify-content-center align-items-end" 
                 style={{ height:'180px', width:'100%' }}>
                <MiddleComponents me={me} />
            </div>
            <div style={{ height:'calc(100% - 180px)', width:'100%' }}>
                <div className="d-flex align-items-end" style={{ height:'100%', width:'300px', float:'left'}}>
                    <ActionBox title="Downloads" onClick={openDownloads}>
                        <Downloads />
                    </ActionBox>
                </div>
                <div className="d-flex justify-content-center align-items-end" 
                     style={{ height:'100%', width:'calc(100% - 600px)', float:'left' }}
                >
                    <ScaledImg imageSrc={ (avatarImage) ? "data:image/png;base64, " + avatarImage : "images/avatar_profile.png" } />
                </div>
                <div className="d-flex align-items-end" style={{ height:'100%', width:'300px', float:'right'}}>
                    <ActionBox title="Internet Accounts" onClick={openInternetAccounts}>
                        <InternetAccounts accounts={accounts} />
                    </ActionBox>
                </div>

            </div>

        </Container>
    )
}
