import { useEffect, useState } from "react"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import InputGroup from "react-bootstrap/InputGroup"

import ReactMarkdown from 'react-markdown'
import noticeFrPath from '../Legal/pirvacynotice20240614.md'

export default function CookieConsent( props )
{
    const [ showConsentModal, setShowConsentModal ] = useState( false )
    const [ showPrivacyNotice, setShowPrivacyNotive ] = useState( false )

    const [markdown, setMarkdown] = useState("");

    useEffect( () => {
        if( localStorage.cookie_consent == null )
        {
            setShowConsentModal( true )

            fetch(noticeFrPath)
                .then((res) => res.text())
                .then((text) => setMarkdown(text));
        }
    },[])

    const accept_cookie_policy = () => {
        localStorage.cookie_consent = JSON.stringify({accepted:true, date:Date.now()})
        setShowConsentModal( false )
    }

    return (
        <>
            { props.children }
            <Modal size="lg" show={showConsentModal}>
                <Modal.Header>
                    <Modal.Title style={{ width:'100%' }}>
                        <div className="d-flex justify-content-center" 
                             style={{ width:'100%'}}>
                            <img src={'/images/logo-motion-0205_1.gif'} 
                                 style={{ width: 100, height: 'auto',bottom:'5px', 
                                      paddingTop:'10px'}}
                            />
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height:'350px', width:'100%', overflowY:'scroll',
                                     fontSize:'12px'}}>
                    <ReactMarkdown>
                        {markdown}
                    </ReactMarkdown>
                    {/*
                    <iframe style={{width:'100%', height:'100%', fontSize:'10px'}} src="/legal/privacynotice.html" seamless>
                    </iframe>
                    */}
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-center" style={{width:'100%'}}>
                        <Button onClick={accept_cookie_policy} variant="dark">Accept</Button>
                    </div>
                </Modal.Footer> 
            </Modal>
        </>
    )
}
