import { useState, useEffect, useRef } from "react"
import Form from 'react-bootstrap/Form'
import EffectItem from './EffectItem'

import Increment from '../../SpiceUI/Increment'
import ScalarBox from "../../SpiceUI/ScalarBox"

import { FaPlusSquare, FaRegWindowClose } from "react-icons/fa";
import { CiSquarePlus } from "react-icons/ci";

import Collapse from 'react-bootstrap/Collapse';
import Checkmark from '../../SpiceUI/Checkmark'

export default function ScaleEffect(props) {
    const layers = props.layers
    const setLayers = props.setLayers
    const layersCfg = props.layersCfg
    const selected = props.selected
    const selectedIdx = props.selectedIdx

    const [hasScale, setHasScale] = useState(null)
    const [values, setValues ] = useState(null)
    const [speed, setSpeed] = useState(null)
    const [mode, setMode ] = useState(null)

    const loaded = useRef(false)

    const reload = () => {
        if (selectedIdx < 0 && selected == "") {
            return;
        } 

        if( !layersCfg.current[selected] )
        {
            return
        }

        //setHasShaker(false)
        let cfg = layersCfg.current[selected].effects

        if( 'scale' in cfg )
        {
            setValues(cfg.scale.values)
            setSpeed(cfg.scale.speed)
            setMode(cfg.scale.mode)
            setHasScale( true )
        }
        else 
        {
            setHasScale( false )
        }
    }

    useEffect(() => {
        reload()
    }, [])

    useEffect(() => {
        if( hasScale == null )
        {
            return
        }


        if (selectedIdx < 0) {
            return
        }

        if( !layersCfg.current[selected] )
        {
            return
        }

        if (!hasScale && layersCfg.current[selected].effects.scale) {
            delete layersCfg.current[selected].effects.scale
            setLayers([...layers])
        }

        if (hasScale && !layersCfg.current[selected].effects.scale) {
            layersCfg.current[selected].effects.scale = { values: [25,50], speed: 10, mode:'loop' }
            setValues(layersCfg.current[selected].effects.scale.values)
            setSpeed(layersCfg.current[selected].effects.scale.speed)
            setMode(layersCfg.current[selected].effects.scale.mode)
            setLayers([...layers])
        }

    }, [hasScale])

    useEffect(() => {
        if( !hasScale )
        {
            return
        }

        if (selectedIdx < 0 || values == null || speed == null || mode == null) {
            return
        }

        var scale_data = {
            values: values, 
            speed: speed,
            mode: mode
        }

        layersCfg.current[selected].effects.scale = scale_data

        setLayers([...layers])
    }, [values, speed, mode])

    useEffect(() => {
        reload()
    }, [selected])


    return (
        <EffectItem valid={hasScale} setValid={setHasScale} name="Scale">
            <div className="d-flex align-items-center justify-content-center m-1" >
                <div className=" d-flex align-items-center justify-content-center m-1" 
                     style={{ width: '50px', float: 'left', backgroundColor: '#FFF', 
                              height: '31px',fontSize:'12px' }} >
                    Range
                </div>
                <ScalarBox value={values}  
                           setValue={setValues}
                           step={1}
                />
            </div>
            <div className="d-flex align-items-center justify-content-center m-1" >
                <div className=" d-flex align-items-center justify-content-center m-1" 
                     style={{ width: '50px', float: 'left', backgroundColor: '#FFF', 
                              height: '31px',fontSize:'12px' }} >
                    Speed
                </div>
                <ScalarBox value={speed}  
                           setValue={setSpeed}
                           step={1}
                />
            </div>
            <div className="d-flex align-items-center justify-content-center m-1" >
                <div className=" d-flex align-items-center justify-content-center m-1" 
                     style={{ width: '50px', float: 'left', backgroundColor: '#FFF', 
                              height: '31px',fontSize:'12px' }} >
                    Mode
                </div>
                <Form.Select style={{ width:'calc(100% - 50px)'}} size="sm" value={mode} onChange={ e => setMode( e.target.value )}>
                    <option value="loop">Loop</option>
                    <option value="grow">Grow</option>
                    <option value="shrink">Shrink</option>
                </Form.Select>
            </div>

        </EffectItem>
    )

}

