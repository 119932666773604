import { useState, useEffect } from "react"
import Form from 'react-bootstrap/Form'
//import Button from 'react-bootstrap/Button'
import Button from '@mui/material/Button'
import EffectItem from './EffectItem'
import Increment from './Increment'
import ScalarBox from "../../SpiceUI/ScalarBox"
import RegionSelectionModal from './RegionSelectModal'
import { LiaPepperHotSolid } from "react-icons/lia";

export default function EmoticonEffect( props )
{
    const layers = props.layers 
    const setLayers = props.setLayers 
    const layersCfg = props.layersCfg
    const selected = props.selected 
    const selectedIdx = props.selectedIdx
    const modalOpen = props.modalOpen
    const canPlace = props.canPlace
    const disabled = props.disabled

    const [ hasEmoticon, setHasEmoticon ] = useState(null)
    const [ mode, setMode ] = useState(null)
    const [ region, setRegion ] = useState(null)
    const [ showModal, setShowModal ] = useState(false)

    const reload = () => {
        if( selectedIdx < 0 )
        {
            return;
        }

        if( !layersCfg.current[selected] )
        {
            return
        }

        if( layersCfg.current[selected].effects.emoticon != null )
        {
            setMode(layersCfg.current[selected].effects.emoticon.mode)
            setRegion(layersCfg.current[selected].effects.emoticon.region)
            setHasEmoticon( true )
        }
        else 
        {
            setHasEmoticon(false)
        }
    }

    useEffect( () => {
        reload()
    }, [] )

    useEffect( () => {
        if( hasEmoticon == null )
        {
            return
        }

        if( selectedIdx < 0 )
        {
            return
        }

        if( !layersCfg.current[selected] )
        {
            return
        }

        if( !hasEmoticon && layersCfg.current[selected].effects.emoticon  )
        {
            delete layersCfg.current[selected].effects.emoticon 
            setLayers( [...layers] )
        }

        if( hasEmoticon && !layersCfg.current[selected].effects.emoticon )
        {
            layersCfg.current[selected].effects.emoticon = {mode:'last', region:null} 
            setMode(layersCfg.current[selected].effects.emoticon.mode )
            setRegion(layersCfg.current[selected].effects.emoticon.region)

            setLayers( [...layers] )
        }
    },[ hasEmoticon ] )

    useEffect( () => {
        if( !hasEmoticon )
        {
            return
        }

        if (selectedIdx < 0 || mode == null ) {
            return
        }

        if( !layersCfg.current[selected] )
        {
            return
        }

        var emoticon_data = {
            mode: mode, 
            region: region
        }

        layersCfg.current[selected].effects.emoticon = emoticon_data

        setLayers([...layers])

    },[ mode, region ])

    useEffect( () => {
        reload()
    },[selected])

    useEffect( () => {
        modalOpen.current = showModal
    },[showModal])

    return (
        <EffectItem valid={hasEmoticon} setValid={setHasEmoticon} 
                    name={ <>Emoticon <LiaPepperHotSolid style={{ marginLeft:'10px'}}/></> }
                    disabled={disabled}
        >
            <div className="d-flex align-items-center justify-content-center m-1" >
                <div className=" d-flex align-items-center justify-content-center m-1" 
                     style={{ width: '50px', float: 'left', backgroundColor: '#FFF', 
                              height: '31px',fontSize:'12px' }} >
                    Mode
                </div>

                <Form.Select style={{ width:'100%'}} size="sm" 
                             value={mode} 
                             onChange={ e => setMode( e.target.value )}
                >
                    <option value="last">Last</option>
                    <option value="super_emot_1">Super Emot (1 min)</option>
                    <option value="super_emot_5">Super Emot (5 min)</option>
                </Form.Select>
            </div>
            { canPlace &&
            <div className="d-flex align-items-center justify-content-center m-1" >
                <div className=" d-flex align-items-center justify-content-center m-1" 
                     style={{ width: '100%', backgroundColor: '#FFF', 
                              height: '31px',fontSize:'12px' }} >
                    <Button size="sm" 
                            onClick={ e => setShowModal(true)} 
                            style={{ width:'100%', textTransform:'none' }}
                            variant={( region == null ) ? "contained" : ""}
                            size="small"
                    > 
                        Place emoticon
                    </Button>
                </div>
            </div>
            }

            { layers[selectedIdx] &&
                <RegionSelectionModal showModal={showModal} 
                                      setShowModal={setShowModal} 
                                      label="Emoticon"
                                      image={layers[selectedIdx].patch} 
                                      region={region}
                                      setRegion={setRegion}
                />
            }

        </EffectItem>
    )
}

