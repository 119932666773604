import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'

import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from '@mui/material/Button'

import { backendAccessPost, logout } from '../../Utils/Authentication'
import { FaUser } from "react-icons/fa6";
import { FaUserCog } from "react-icons/fa";
import { IoLogOut } from "react-icons/io5";
import { FaShoppingCart } from "react-icons/fa";
import { FaCoins } from "react-icons/fa6";
import { RiFeedbackFill } from "react-icons/ri";
import { IoCloudDownloadSharp } from "react-icons/io5";
import { RiHandCoinFill } from "react-icons/ri";
import { FaDiscord } from "react-icons/fa";
import { MdWorkspacePremium } from "react-icons/md";
import { LiaPepperHotSolid } from "react-icons/lia";
import { FaCircleDown } from "react-icons/fa6";

import { binaryStringToArrayBuffer } from '../Misc/FileTools';

export default function Header(props) {
    const me = props.me
    const avatarImage = props.avatarImage
    const state = props.state 
    const setState = props.setState 
    const stateLock = props.stateLock
    const setFutureState = props.setFutureState
    const displayName = props.displayName
    const userPoints = props.userPoints
    const setUserPoints = props.setUserPoints
    const accountType = props.accountType
    const setAccountType = props.setAccountType

    const navigate = useNavigate()

    const [ firstname, setFirstname ] = useState( (me) ? me.first_name : "" )

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const [ showFeedbackModal, setShowFeedbackModal ] = useState(false)
    const [ feedback, setFeedback ] = useState("")

    const [ showRedeemModal, setShowRedeemModal ] = useState(false)
    const [ redeemCode, setRedeemCode ] = useState("")

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    useEffect( () => {
        setFirstname( (me) ? me.first_name : "" )
    },[ me ])

    useEffect( () => {
        if( showFeedbackModal )
        {
            setFeedback("")
        }
    },[ showFeedbackModal ] )

    useEffect( () => {
        if( showRedeemModal )
        {
            setRedeemCode("")
        }
    },[ showRedeemModal ] )

    const logoutFunc = () => {
        setAnchorEl(null)
        logout()
        navigate("/")
    }

    const downloadProfilePic = () => {
        if( !avatarImage )
        {
            return
        }

        var buffer_bs = atob(avatarImage)
        var buffer = binaryStringToArrayBuffer(buffer_bs)

        var b = new Blob([buffer]);
        var csvURL = window.URL.createObjectURL(b);
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'avatar.png');
        tempLink.click();
    }

    const updateState = ( s ) => {
        if( stateLock.current == false )
        {
            setState( s )
        }
        else 
        {
            setFutureState(s)
        }
    }

    const upgradeAccount = () => {
        let data = {}
        backendAccessPost('/api/account/upgrade/', data).then( res => {
            if( res.error_message )
            {
                alert( res.error_message )
                return 
            }

            setAccountType( res.type )
            setUserPoints( res.points )
        })
    }

    const downgradeAccount = () => {
        let data = {}
        backendAccessPost('/api/account/downgrade/', data).then( res => {
            if( res.error_message )
            {
                alert( res.error_message )
                return 
            }
            setAccountType( res.type )
        })
    }

    const openProfile = () => {
        updateState("")
        //navigate("/home")
    }

    const openWidgets = () => {
        updateState("1")
        //navigate("/widgets")
    }

    const openWidgetEditor = () => {
        updateState("2")
        //navigate("/widget-editor")
    }

    const openAvatar = () => {
        updateState("4")
        //navigate("/avatar")
    }

    const openAccounts = () => {
        updateState("5")
        setAnchorEl(null)
        //navigate('/internet-accounts')
    }

    const openDownloads = () => {
        updateState("6")
        setAnchorEl(null)
        //navigate('/downloads')
    }

    const openDiscord = () => {
        window.open("https://discord.gg/4knb57ed", "_blank")
    }

    const submitFeedback = () => {
        if( feedback == "" )
        {
            return 
        }

        let data = {}
        data['notes'] = feedback 

        backendAccessPost('/api/feedback/add-feedback/', data).then( res => {
            if( res == null )
            {
                alert( "Something went wrong." )
                return 
            }

            setShowFeedbackModal( false )
        })
    }

    const submitRedeemCode = () => {
        if( redeemCode == "" )
        {
            return
        }

        let data = {}
        data['code'] = redeemCode

        backendAccessPost('/api/points/redeem/', data).then( res => {
            if( res == null )
            {
                alert( "Something went wrong." )
                return 
            }

            setShowRedeemModal( false )
        })
    }

    return (
        <Container style={{ height:'100px', maxWidth:'1200px' }}>
            <div style={{ width: '100%',padding:10,height:'100%' }}>
                <div className="d-flex justify-content-start align-items-center" 
                     style={{ height:'100%', width:'150px', float:'left'}}> 
                    <img src={'/images/logo-motion-0205_1.gif'} 
                         style={{ width: 'auto', height: '50px', cursor:'pointer'}}
                         onClick={openProfile}/>
                </div>
                <div className="d-flex justify-content-center align-items-center"
                     style={{float:'left',display: 'flex', width:'calc(100% - 300px)', height:'100%' }} >
                    <div style={{ width: '25%', 
                                  borderRight: '1px solid #e7e7e7', 
                                  fontWeight:( state == "1" ) ? 'bold' : '', cursor:'pointer' }} 
                                  onClick={openWidgets}>Widgets</div>
                    <div style={{ width: '25%', 
                                  borderRight: '1px solid #e7e7e7',
                                  fontWeight:( state == "2" ) ? 'bold' : '', cursor:'pointer' }} 
                                  onClick={openWidgetEditor}>Widget Builder</div>
                    <div style={{ width: '25%',
                                  fontWeight:( state == "4" ) ? 'bold' : '', cursor:'pointer' }} 
                                  onClick={openAvatar}>Avatar & Logo</div>
                </div>
                <div style={{float:'right', width:'150px', height:'100%'}}>
                    <div className="d-flex align-items-center"
                         style={{ height:'100%', float:'left' }}> 
                        <IconButton size="sm" onClick={handleClickMenu}>
                            <Avatar src={(avatarImage) ? "data:image/png;base64, " + avatarImage : "images/avatar_profile.png"}
                                    style={{ cursor:'pointer', border:'0.2px solid #DADADA', 
                                             height:'50px', width:'50px' }}
                            />
                        </IconButton>
                    </div>
                </div>
            </div>
            <Menu anchorEl={anchorEl}
                  open={openMenu}
        onClose={handleCloseMenu}
                  onClick={handleCloseMenu}
                  transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }} 
            >
                <MenuItem onClick={openProfile}>
                    <ListItemIcon>
                        <FaUser fontSize="small"/>
                    </ListItemIcon>
                    User profile
                </MenuItem> 
                <MenuItem disabled={accountType != 'mild'} onClick={upgradeAccount}>
                    <ListItemIcon>
                        <LiaPepperHotSolid fontSize="small"/>
                    </ListItemIcon>
                    Upgrade Account
                </MenuItem>
                <MenuItem disabled={accountType == 'mild'} onClick={downgradeAccount}>
                    <ListItemIcon>
                        <FaCircleDown fontSize="small"/>
                    </ListItemIcon>
                    Downgrade Account
                </MenuItem>

                <MenuItem onClick={openAccounts}>
                    <ListItemIcon>
                        <FaUserCog fontSize="small"/>
                    </ListItemIcon>
                    Internet Accounts
                </MenuItem>
                <MenuItem onClick={openDownloads}>
                    <ListItemIcon>
                        <IoCloudDownloadSharp fontSize="small"/>
                    </ListItemIcon>
                    Downloads
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleCloseMenu} disabled>
                    <div style={{ width:'100%', color:'black'}}>
                        <div style={{ float:'right'}}>{ userPoints.points } SPCs</div>
                    </div>
                </MenuItem>
                <MenuItem onClick={ e => setShowRedeemModal(true)}>
                    <ListItemIcon>
                        <RiHandCoinFill fontSize="small"/>
                    </ListItemIcon>
                    Redeem SPC 
                </MenuItem>
                <MenuItem onClick={handleCloseMenu} disabled>
                    <ListItemIcon>
                        <FaShoppingCart fontSize="small"/>
                    </ListItemIcon>
                    SPC Shop
                </MenuItem>
                <Divider />
                <MenuItem onClick={ e => setShowFeedbackModal(true) }>
                    <ListItemIcon>
                        <RiFeedbackFill fontSize="small"/>
                    </ListItemIcon>
                    Feedback
                </MenuItem>
                <MenuItem onClick={openDiscord}>
                    <ListItemIcon>
                        <FaDiscord fontSize="small"/>
                    </ListItemIcon>
                    Our Discord
                </MenuItem>
                <Divider />
                <MenuItem onClick={logoutFunc}>
                    <ListItemIcon>
                        <IoLogOut fontSize="small"/>
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
            <Modal show={showFeedbackModal} 
                   onHide={ e => setShowFeedbackModal(false)}
            >
                <Modal.Header closeButton>
                    Feedback 
                </Modal.Header>
                <Modal.Body>
                    <Form.Control as="textarea" 
                                  style={{ height:'250px', width:'100%', fontSize:'12px'}}
                                  value={feedback} 
                                  onChange={ e => setFeedback( e.target.value) }
                    /> 
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ textTransform:'none'}}  
                            variant="contained" size="sm"
                            onClick={submitFeedback}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showRedeemModal} onHide={ e => setShowRedeemModal(false)}>
                <Modal.Header closeButton>
                    Redeem code
                </Modal.Header>
                <Modal.Body>
                    <Form.Control style={{ width:'100%', fontSize:'12px'}}
                                  value={redeemCode} 
                                  onChange={ e => setRedeemCode(e.target.value) }
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ textTransform:'none' }}  
                            variant="contained" size="sm"
                            onClick={submitRedeemCode}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}
