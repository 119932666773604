import { useState, useEffect, useRef, act } from "react"

import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import EffectItem from './EffectItem'
import Button from '@mui/material/Button'

export function Selector( props )
{
    const image = props.image 
    const region = props.region
    const regionRef = props.regionRef

    const canvasRef = useRef(null)
    const canvasHeight = useRef(150)
    const canvasWidth = useRef(150)

    const frameScale = useRef(null)
    const canvasImage = useRef(null)

    const point0 = useRef(null)
    const point1 = useRef(null)

    const clicked = useRef(false)
    const dragging = useRef(false)
    const action = useRef(-1)

    useEffect( () => {
        regionRef.current = region
    },[])

    const screen2canvas = ( x,y ) => {
        var rect = canvasRef.current.getBoundingClientRect();
        x = x - rect.left
        y = y - rect.top

        if( x < 0 || y < 0 || x >= canvasWidth || y >= canvasHeight )
        {
            return null;
        }

        return {x,y}
    }

    const canvas2image = ( r ) => {
        let x = (r.x - frameScale.current['x'])/frameScale.current['scale']
        let y = (r.y - frameScale.current['y'])/frameScale.current['scale'] 
        let height = r.height/frameScale.current['scale'] 
        let width = r.width/frameScale.current['scale']

        x = x / canvasImage.current.width 
        y = y / canvasImage.current.height 
        width = width / canvasImage.current.width 
        height = height / canvasImage.current.height

        return {x,y,width,height}
    }


    const image2canvas = ( r ) => {
        let r_x = r.x * canvasImage.current.width 
        let r_y = r.y * canvasImage.current.height
        let r_width = r.width * canvasImage.current.width 
        let r_height = r.height * canvasImage.current.height

        let x = r_x * frameScale.current['scale'] + frameScale.current['x']
        let y = r_y * frameScale.current['scale'] + frameScale.current['y']
        let height = r_height * frameScale.current['scale']
        let width = r_width * frameScale.current['scale']

        return {x,y,width,height}
    }

    const pointInRegion = ( x,y, r, offset=0 ) => {
        if( x < r.x - offset || x >= r.x + offset + r.width )
        {
            return false 
        }

        if( y < r.y - offset || y >= r.y + offset + r.height )
        {
            return false
        }

        return true
    }

    const determineAction = ( x,y,r ) => {
        let r_corner = { x:r.x+r.width - 5, y:r.y + r.height -5, width:10,height:10 }

        if( pointInRegion( x,y,r_corner) )
        {
            return 1 // Scale  
        }
        else if( pointInRegion( x,y,r ) )
        {
            return 0 // Move
        }

        return -1
    }

    const updateCanvasSize = () => {
        const canvas = canvasRef.current
        let bounds = canvas.getBoundingClientRect()
        canvasHeight.current = bounds.height
        canvasWidth.current = bounds.width

        canvas.height = bounds.height 
        canvas.width = bounds.width
    }

    const calcFrameScale = () => {
        
        let height = canvasImage.current.height
        let width = canvasImage.current.width

        var scale = Math.min( canvasWidth.current/width, canvasHeight.current/height );
        var wdiff = Math.abs( canvasWidth.current - width*scale );
        var hdiff = Math.abs( canvasHeight.current - height*scale );


        let frame = {}
        frame['x'] = wdiff/2 
        frame['y'] = hdiff/2 
        frame['scale'] = scale 
        frame['h'] = canvasHeight.current
        frame['w'] = canvasWidth.current

        frameScale.current = frame

        if( regionRef.current == null )
        {
            //let m = Math.min( height, width )/3;
            regionRef.current = { x:0, y:0, width:0.3, height:0.3 }; 
        }
    }

    const redraw = () => 
    {
        if( canvasImage.current == null )
        {
            return
        }

        updateCanvasSize()
        calcFrameScale()

        let scale = frameScale.current

        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d')

        ctx.clearRect(0, 0, canvasWidth.current, canvasHeight.current);

        ctx.rect(0,0,canvasWidth.current,canvasHeight.current)
        ctx.fillStyle = "white"
        ctx.fill()

        let imageRect = { x : scale.x, 
                          y : scale.y,
                          width : canvasImage.current.width * scale.scale,
                          height : canvasImage.current.height * scale.scale }

        ctx.drawImage( canvasImage.current,
                       imageRect.x,
                       imageRect.y,
                       imageRect.width,
                       imageRect.height );

        if( point0.current != null && point1.current != null )
        {
            ctx.fillStyle = "red";
            ctx.globalAlpha = 0.2;
            ctx.fillRect( point0.current.x,
                          point0.current.y,
                          point1.current.x - point0.current.x,
                          point1.current.y - point0.current.y )
            ctx.globalAlpha = 1.0;
        }
        else if( regionRef.current != null )
        {
            let r = image2canvas( regionRef.current )

            ctx.setLineDash([5,5])
            ctx.strokeStyle = "lightblue";
            //ctx.globalAlpha = 0.2;

            ctx.lineWidth = 2

            ctx.strokeRect( r.x, 
                            r.y, 
                            r.width,
                            r.height );
            ctx.globalAlpha = 1.0;

            ctx.lineWidth = 1
            ctx.setLineDash([1,0])

            ctx.fillStyle = "black"
            ctx.fillRect( r.x + r.width - 5, 
                          r.y + r.height - 5, 
                          10,
                          10 );

            ctx.strokeRect( r.x + r.width - 5, 
                            r.y + r.height - 5, 
                            10,
                            10 )
        }
    }

    const resizeListener = (e) => {
        redraw()
    }

    const mouseDownListener = ( e ) => {
        if( regionRef.current == null )
        {
            return
        }

        point0.current= null

        let p = screen2canvas( e.clientX, e.clientY )
        let r = image2canvas( regionRef.current )

        if( p == null )
        {
            return
        }

        let check = pointInRegion(p.x,p.y,r)
        action.current = determineAction(p.x,p.y,r)
    }

    const mouseMoveListener = ( e ) => {

        if( action.current == -1 )
        {
            return
        }

        if( regionRef.current == null )
        {
            return
        }

        if( action.current == 0 )
        {
            let r = image2canvas( regionRef.current )
            r.x += e.movementX 
            r.y += e.movementY 
            regionRef.current = canvas2image(r)
        }
        else if( action.current == 1 )
        {
            let r = image2canvas( regionRef.current )
            r.width += e.movementX 
            r.height += e.movementY 
            regionRef.current = canvas2image(r)
        }

        redraw()
    }

    const mouseUpListener = ( e ) => {

        clicked.current = false
        action.current = -1

        redraw()
    }

    useEffect( () => {
        canvasImage.current = new Image()
        canvasImage.current.onload = function() {
                redraw()
        }
        canvasImage.current.src = 'data:image/png;base64, ' + image

        window.addEventListener("resize", resizeListener )
        window.addEventListener("mousedown", mouseDownListener )
        window.addEventListener("mousemove", mouseMoveListener )
        window.addEventListener("mouseup", mouseUpListener )

        return () => {
            window.removeEventListener("resize", resizeListener )
            window.removeEventListener("mousedown", mouseDownListener )
            window.removeEventListener("mousemove", mouseMoveListener )
            window.removeEventListener("mouseup", mouseUpListener )
        }
    },[])

    return (
        <div style={{ height:'100%', width:'100%' }}>
            <canvas ref={canvasRef} 
                height={canvasHeight.current} 
                width={canvasWidth.current} 
                style={{ height:"100%", width:"100%" }} 
            />

        </div>
    )
}

export default function RegionSelectionModal( props )
{
    const showModal = props.showModal 
    const setShowModal = props.setShowModal
    const label = props.label

    const image = props.image
    const region = props.region
    const setRegion = props.setRegion

    const regionRef = useRef( region )

    const close = () => {
        setShowModal( false )
    }

    const saveClose = () => {
        setRegion( { ...regionRef.current} )
        setShowModal( false )
    }

    return (
        <Modal show={showModal} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>{ label }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ width:'100%', height:'300px' }} >
                    <Selector image={image} region={region} regionRef={regionRef}/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="contained" onClick={close} size="sm" style={{ marginRight:'5px', textTransform:'none' }}>
                    Close
                </Button>
                <Button variant="contained" onClick={saveClose} size="sm" style={{ textTransform:'none' }}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

