import ProgressBar from 'react-bootstrap/ProgressBar';

export default function LoadingProgress( props )
{
    const value = props.value 
    const max = props.max 

    return (
        <div className="d-flex justify-content-center align-items-center"
             style={{ height:'100%', width:'100%'}}>
            <ProgressBar now={value} max={max} style={{ height:'15px', width:'150px'}} />
        </div>
    )
}
