import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useEffect, useState, useRef } from 'react'

// Pages
import Landing from './Pages/Landing'
import Home from './Pages/Home'
import Login from './Pages/Login'

// Authentication
import PrivateRoute from './Components/PrivateRoute'
import AlreadyLoggedIn from './Components/AlreadyLoggedIn'

import CookieConsent from './Legal/CookieConsent';
import TermsOfUse from './Legal/TermsOfUse'

// Auth login 
import TwitchLogin from './Components/InternetAccounts/TwitchLogin';

function App() { 
    return (
        <div className="App">
            <CookieConsent>
                <Router>
                    <Routes>
                        <Route path="/" element={
                            <AlreadyLoggedIn to="/home">
                                <Landing />
                            </AlreadyLoggedIn>
                        }/> 
                        <Route path="/Login" element={
                            <AlreadyLoggedIn to="/home">
                                <Login to="/home" />
                            </AlreadyLoggedIn>
                        }/>
                        <Route path="/home" element={
                            <PrivateRoute to="/login">
                                <Home/>
                            </PrivateRoute>
                        }/>
                        <Route path="/oauth2/twitch" element={<TwitchLogin />}/>
                    </Routes>
                </Router>
            </CookieConsent>
        </div>
    );
}

export default App;

