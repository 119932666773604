import { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import Container from 'react-bootstrap/Container'
import Header from '../Components/Header/Header'
import { backendAccessPost, backendAccessGet, backendMe, logout } from '../Utils/Authentication'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Loading from '../Components/UserHome/Loading'
import Profile from '../Components/Profile/Profile'
import Avatar from '../Components/Avatar/Avatar'
import Downloads from '../Components/Downloads/Downloads'

import ReactMarkdown from 'react-markdown'
import tosFrPath from '../Legal/tos20240614.md'

import WidgetBrowser from '../Components/WidgetBrowser/WidgetBrowser'
import WidgetEditor from '../Components/WidgetEditor/WidgetEditor'
import InternetAccounts from '../Components/InternetAccounts/InternetAccounts'

export function Selector(props) {
    const state = props.state
    const setState = props.setState
    const stateLock = props.stateLock
    const futureState = props.futureState
    const setFutureState = props.setFutureState

    const me = props.me
    const setMe = props.setMe
    const avatarImage = props.avatarImage
    const setAvatarImage = props.setAvatarImage
    const accounts = props.accounts 
    const setAcocunts = props.setAccounts
    const compHeight = props.compHeight

    const userState = props.userState
    const setUserState = props.setUserState

    const userConfig = props.userConfig 
    const setUserConfig = props.setUserConfig

    const userPoints = props.userPoints 
    const setUserPoints = props.setUserPoints 

    const accountType = props.accountType 
    const setAccountType = props.setAccountType

    const widgetIdToEdit = props.widgetIdToEdit
    const setWidgetIdToEdit = props.setWidgetIdToEdit

    if (state == "") {
        return (
            <Profile me={me}
                     avatarImage={avatarImage} 
                     compHeight={compHeight} 
                     setState={setState}
                     accounts={accounts} 
            />
        )
    }

    if (state == "1") {
        return (
            <WidgetBrowser setWidgetIdToEdit={setWidgetIdToEdit} 
                           userConfig={userConfig} 
            />
        )
    }

    if (state == "2") {
        return (
            <WidgetEditor setState={setState} 
                          stateLock={stateLock} 
                          futureState={futureState} 
                          setFutureState={setFutureState}
                          widgetIdToEdit={widgetIdToEdit} 
                          setWidgetIdToEdit={setWidgetIdToEdit} 
                          accountType={accountType}
            />
        )
    }

    if (state == "3") {
        return (
            <>Apps</>
        )
    }

    if (state == "4") {
        return (
            <Avatar setMe={setMe} 
                    setAvatarImage={setAvatarImage} 
                    userConfig={userConfig}
                    accountType={accountType}
            />
        )
    }

    if (state == "5") {
        return (
            <InternetAccounts accounts={accounts} 
                              setAccounts={setAcocunts} 
            />
        )
    }

    if (state == "6") {
        return (
            <Downloads />
        )
    }

    return (
        <>Invalid components</>
    )
}

export default function Home( props ) {

    const location = useLocation()

    const compHeight = 'calc(100% - 100px)'

    const [me, setMe] = useState(null)
    const [accounts, setAccounts] = useState(null)
    const [displayName, setDiplayName] = useState(null)
    const [avatarImage, setAvatarImage] = useState(null)

    const [state, setState] = useState("")

    const stateLock = useRef(false)
    const [futureState, setFutureState] = useState(null)

    const [userState, setUserState] = useState(null)
    const [userConfig, setUserConfig] = useState(null)
    const [userPoints, setUserPoints] = useState(null)
    const [accountType, setAccountType ] = useState('basic')

    const userStateJson = useRef("")

    const [widgetIdToEdit, setWidgetIdToEdit] = useState(null)

    const loadAvatar = async ( avatar_ref ) => {
        let load_avatar = true 
        if( localStorage.avatar )
        {
            let avatar_local = await JSON.parse( localStorage.avatar )
            if( avatar_local.checksum_md5 == avatar_ref.checksum_md5 )
            {
                setAvatarImage( avatar_local.img )
                load_avatar = false
            }
        }

        if( load_avatar )
        {
            let avatar_res = await backendAccessGet('/api/avatar/get-avatar/')

            if( avatar_res != null )
            {
                setAvatarImage( avatar_res.avatar.img )
                localStorage.avatar = JSON.stringify( avatar_res.avatar )
            }
        }
    }

    useEffect(() => {

        backendMe().then(res => {
            if (res == null) {
                logout()
                return
            }

            setMe(res.me)

            loadAvatar( res.avatar ).then( res => {})

            if( res.state )
            {
                let s = res.staete
                userStateJson.current = JSON.stringify(s)
                setUserState(res.state)
            }
            else
            {
                setUserState({})
            }

            if( res.user_config )
            {
                setUserConfig(res.user_config)
                setAccountType( res.user_config.config.account )
            }
            else 
            {
                setUserConfig({})
            }

            if( res.points )
            {
                setUserPoints( res.points )
            }
            else 
            {
                setUserPoints( {'points':0} )
            }

            if( res.accounts )
            {
                setAccounts( res.accounts )
                if( res.accounts.twitch )
                {
                    setDiplayName( res.accounts.twitch.username )
                }
                else 
                {
                    setDiplayName( res.me.username )
                }
            }

        })

        if (location.state) {
            console.log( location.state )
            if (location.state.state) {
                setState(location.state.state)
            }
        }
    }, [])

    useEffect(() => {
        if (userState == null) {
            return
        }

        if (JSON.stringify(userState) == userStateJson.current) {
            return
        }

        backendAccessPost('/api/state/set/', userState).then(res => {
        })

    }, [userState])

    useEffect(() => {
        if (widgetIdToEdit == null) {
            return
        }

        setState("2")
    }, [widgetIdToEdit])

    useEffect(() => {
        stateLock.current = false
        setFutureState(null)
    }, [state])

    
    return (
        <div className="d-flex justify-content-center" style={{ width: '100%', height: '100vh' }}>
            { ( me && userState && userConfig ) &&
                <div style={{ height: '100%', width: '100%' }}>
                    <div style={{ width: '100%', height: `${compHeight}` }}>
                        <Container style={{ maxWidth: '1200px' }}>
                            <Header me={me} avatarImage={avatarImage} 
                                    state={state} setState={setState} 
                                    stateLock={stateLock} 
                                    setFutureState={setFutureState}
                                    displayName={displayName}
                                    userPoints={userPoints}
                                    setUserPoints={setUserPoints}
                                    accountType={accountType}
                                    setAccountType={setAccountType}
                            />
                        </Container> 
                        <Selector state={state}
                            setState={setState}
                            stateLock={stateLock}
                            futureState={futureState}
                            setFutureState={setFutureState}
                            me={me} 
                            setMe={setMe}
                            avatarImage={avatarImage}
                            setAvatarImage={setAvatarImage}
                            accounts={accounts}
                            setAccounts={setAccounts}
                            userConfig={userConfig}
                            setUserConfig={setUserConfig}
                            userPonts={userPoints}
                            setUserPoints={setUserPoints}
                            accountType={accountType}
                            setAccountType={setAccountType}
                            compHeight={compHeight}
                            userState={userState}
                            setUserState={setUserState}
                            widgetIdToEdit={widgetIdToEdit}
                            setWidgetIdToEdit={setWidgetIdToEdit}
                        />
                    </div>

                </div>
            }
        </div>
    )
}
