import { useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'

import { loginWithTwitch } from './Utils'
import { backendAccessGet, backendAccessPost } from '../../Utils/Authentication'

import { FaTwitch } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";

export function TwitchAccount( props )
{  
    const accounts = props.accounts 
    const setAccounts = props.setAccounts

    const revokeAccess = () => {
        let data = {}
        data['platform'] = 'twitch'

        backendAccessPost('/api/accounts/revoke-access/', data ).then( res => {
            if( res.error_message )
            {
                alert( res.error_message )
                return 
            }

            setAccounts( res.accounts )
        })
    }

    return (
        <div style={{ width:'100%', padding:'20px', borderRadius:'5px'}}>
                <div style={{ width:'100%', marginBottom:'50px'}}>
                    <div  className="fw-bolder" style={{ float:'left', color:'#303030'}}>
                        <FaTwitch style={{ color: (accounts.twitch) ? '#6441a5' : '#D7D7D7', fontSize:'30px', marginRight:'10px' }} />
                        Twitch
                    </div> 
                </div>
                { accounts.twitch &&
                <>
                <div className="d-flex justify-content-start" style={{ width:'100%', marginBottom:'5px'}}>
                    Username: <b style={{ marginLeft:'10px'}}>{ accounts.twitch.username }</b>
                </div>
                <div className="d-flex justify-content-start" style={{ width:'100%', marginBottom:'20px'}}>
                    Access date: <b style={{ marginLeft:'10px'}}>{ accounts.twitch.data.timestamp }</b>
                </div>
                <div className="d-flex justify-content-start" style={{ width:'100%'}}>
                    <Button size="sm" onClick={revokeAccess} variant="dark">Revoke access</Button>
                </div>
                </>
                }

                { ( accounts.twitch == null ) &&
                    <div className="d-flex justify-content-start" style={{ width:'100%', marginTop:'30px'}}>
                        <Button size="sm" onClick={e => loginWithTwitch("accounts")}>Grant access</Button>
                    </div>
                }
        </div>
    )

    if( accounts.twitch )
    {
        return (
            <div>
                <div className="d-flex justify-content-left align-items-center"
                    style={{ height:'40px', width:'100%'}}>
                    <b>Twitch</b>
                </div>
            <div className="d-flex justify-content-left align-items-center"
                 style={{ height:'20px', width:'100%'}}>
                <Button onClick={ e => loginWithTwitch("accounts") }>Connect</Button>
            </div>
        </div>
        )
    }

    return 
}

export default function InternetAccounts( props )
{
    const accounts = props.accounts
    const setAccounts = props.setAccounts 

    useEffect( () => {
        backendAccessGet('/api/accounts/get-accounts/').then( res => {
            if( res.error_message )
            {
                alert( res.error_message )
                return
            }

            setAccounts( res.accounts )
        })
    },[])

    return (
        <Container style={{ maxWidth:'500px', height:'100%', overflowY:'scroll'}}>
            <TwitchAccount accounts={accounts} setAccounts={setAccounts} />
        </Container>
    )
}
