import { useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import { FaCheckCircle } from "react-icons/fa";
import { CiCirclePlus } from "react-icons/ci";

import Checkmark from '../../SpiceUI/Checkmark'
import Collapse from 'react-bootstrap/Collapse';

export default function EffectItem(props) {
    const valid = props.valid
    const setValid = props.setValid
    const name = props.name
    const disabled = ( props.disabled == null ) ? false : props.disabled

    if( disabled )
    {
        return (
        <div style={{ width:'100%', padding:'5px', color:'grey' }}>
            <Checkmark valid={false} setValid={e => {}} name={name} /> 
            <hr style={{ marginLeft: 0, width: '100%' }} /> 
        </div>
        )
    }

    return (
        <div style={{ width:'100%', padding:'5px' }}>
            <Checkmark valid={valid} setValid={setValid} name={name} /> 
            { valid &&
                <div style={{ width:'100%' }}>
                    {props.children}
                </div>
            }
            <hr style={{ marginLeft: 0, width: '100%' }} />
            
        </div>
    )
}

