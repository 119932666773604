import { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { backendAccessGet, backendAccessPost } from '../../Utils/Authentication'
import { FaWindows } from "react-icons/fa";
import ReactMarkdown from 'react-markdown'

import instructions from './Instructions.md'
import {Link} from 'react-router-dom';


import SpiceStream from '../../Downloads/software/1-003-beta/SpiceStream.msi'
import SpicePlugin from '../../Downloads/software/1-003-beta/SpicePlugin.zip'



export function DownloadBox( props )
{
    return (
        <div style={{ padding:'10px'}}>
        <div style={{ padding:'10px', backgroundColor:'#F8F8F8', borderRadius:'5px', width:'300px' }}>
                { props.children }
            </div>
        </div>
    )
}

export default function Downloads( props )
{
    const [markdown, setMarkdown] = useState("");


    const [ fSize, setFSize ] = useState(null)

    useEffect( () => {
        fetch(instructions)
                .then((res) => res.text())
                .then((text) => setMarkdown(text));
    },[])

    return (
        <Container style={{ maxWidth:'800px', height:'100%', 
                            overflowY:'scroll', padding:'10px'}}>
            <div className="d-flex justify-content-center align-items-center"
                 style={{ marginBottom:'10px' }}>
                <b>Downloads</b>
            </div>

            <div className="d-flex justify-content-center" style={{ marginBottom:'30px'}}>
                <div>
                    <DownloadBox>
                        <div>
                            Spice.Stream (v 1.003-beta)
                        </div>
                        <div>
                            <Link to={SpiceStream}
                                  download="Spice.Stream.msi"
                                  target="_blank"
                                  rel="noreferrer"
                            >
                                <FaWindows style={{ color:'#0d6efd', cursor:'pointer'}} /> { fSize }
                            </Link>
                        </div>
                    </DownloadBox>
                </div>
                <div>
                    <DownloadBox>
                        <div>
                            OBS Plugins (v 1.003-beta) 
                        </div>
                        <div>
                            <Link to={SpicePlugin}
                                  download="SpicePlugin.zip"
                                  target="_blank"
                                  rel="noreferrer"
                            >
                                <FaWindows style={{ color:'#0d6efd', cursor:'pointer'}} />
                            </Link>
                        </div>
                    </DownloadBox>
                </div>
            </div>
        <div style={{ width:'100%', textAlign:'left' }}> 
                <ReactMarkdown style={{ justifyContent:'start' }}>
                    {markdown}
                </ReactMarkdown>
            </div>

        
        </Container>
    )
}
