
import { useState, useEfect, useRef } from 'react'



export default function ScaledImg( props )
{
    const divRef = useRef(null)

    const imageSrc = props.imageSrc

    return (
        <div className="d-flex justify-content-center align-items-end" 
             style={{ width:'100%', height:'100%' }}>
            <img src={imageSrc} style={{ maxHeight:'100%', maxWidth:'100%' }}/>
        </div>
    )
}
