import { useState, useEffect } from 'react'

import { Navigate } from 'react-router-dom'
import { verify } from '../Utils/Authentication'

function AlreadyLoggedIn(props) {
    const destination = props.to
    const children = props.children

    const [ loaded, setLoadded ] = useState( false )
    const [ auth, setAuth ] = useState( false )

    useEffect( () => {
        verify().then( a => {
            setAuth(a)
            setLoadded(true)
        })
    }, [])

    if( !loaded )
    {
        return null
    }

    return auth ? <Navigate to={destination} /> : children ;
}

export default AlreadyLoggedIn;
