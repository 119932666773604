import { useState, useEffect, useRef } from "react"
import Form from 'react-bootstrap/Form'
import EffectItem from './EffectItem'

import Increment from '../../SpiceUI/Increment'
import ScalarBox from "../../SpiceUI/ScalarBox"

import { FaPlusSquare, FaRegWindowClose } from "react-icons/fa";
import { CiSquarePlus } from "react-icons/ci";

import Collapse from 'react-bootstrap/Collapse';
import Checkmark from '../../SpiceUI/Checkmark'


export default function ShakerEffect(props) {
    const layers = props.layers
    const setLayers = props.setLayers
    const layersCfg = props.layersCfg
    const selected = props.selected
    const selectedIdx = props.selectedIdx

    const [hasShaker, setHasShaker] = useState(null)
    const [shakerHeight, setShakerHeight] = useState(null)
    const [shakerWidth, setShakerWidth] = useState(null)
    const [shakerSpeed, setShakerSpeed] = useState(null)

    const loaded = useRef(false)

    const reload = () => {
        if (selectedIdx < 0 && selected == "") {
            return;
        }
        
        if( !layersCfg.current[selected] )
        {
            return
        }

        let cfg = layersCfg.current[selected].effects

        if( 'shaker' in cfg )
        {
            setShakerHeight(cfg.shaker.height)
            setShakerWidth(cfg.shaker.width)
            setShakerSpeed(cfg.shaker.speed)
            setHasShaker( true )
        }
        else 
        {
            setHasShaker( false )
        }
    }

    useEffect(() => {
        reload()
    }, [])

    useEffect(() => {
        if( hasShaker == null )
        {
            return
        }

        if (selectedIdx < 0) {
            return
        }
         
        if( !layersCfg.current[selected] )
        {
            return
        }

        if (!hasShaker && layersCfg.current[selected].effects.shaker) {
            delete layersCfg.current[selected].effects.shaker
            setLayers([...layers])
        }

        if (hasShaker && !layersCfg.current[selected].effects.shaker) {
            layersCfg.current[selected].effects.shaker = { height: 10, width: 10, speed: 10 }
            setShakerHeight(layersCfg.current[selected].effects.shaker.height)
            setShakerWidth(layersCfg.current[selected].effects.shaker.width)
            setShakerSpeed(layersCfg.current[selected].effects.shaker.speed)

            setLayers([...layers])
        }

    }, [hasShaker])

    useEffect(() => {
        if( !hasShaker )
        {
            return
        }

        if (selectedIdx < 0 || shakerHeight == null || shakerWidth == null || shakerSpeed == null) {
            return
        }

        var shaker_data = {
            height: parseInt(shakerHeight),
            width: parseInt(shakerWidth),
            speed: parseInt(shakerSpeed)
        }

        layersCfg.current[selected].effects.shaker = shaker_data

        setLayers([...layers])
    }, [shakerHeight, shakerWidth, shakerSpeed])

    useEffect(() => {
        reload()
    }, [selected])


    return (
        <EffectItem valid={hasShaker} setValid={setHasShaker} name="Shaker">
            <div className="d-flex align-items-center justify-content-center m-1" >
                <div className=" d-flex align-items-center justify-content-center m-1" 
                     style={{ width: '50px', float: 'left', backgroundColor: '#FFF', 
                              height: '31px',fontSize:'12px' }} >
                    Height
                </div>
                <ScalarBox value={parseInt(shakerHeight)}  
                           setValue={setShakerHeight}
                           step={1}
                           lowerBound={0}
                           upperBound={100} 
                />
            </div>
            <div className="d-flex align-items-center justify-content-center m-1" >
                <div className=" d-flex align-items-center justify-content-center m-1" 
                     style={{ width: '50px', float: 'left', backgroundColor: '#FFF', 
                              height: '31px',fontSize:'12px' }} >
                    Width
                </div>
                <ScalarBox value={parseInt(shakerWidth)}  
                           setValue={setShakerWidth}
                           step={1}
                           lowerBound={0}
                           upperBound={100} 
                />
            </div>
            <div className="d-flex align-items-center justify-content-center m-1" >
                <div className="d-flex align-items-center justify-content-center m-1" 
                     style={{ width: '50px', float: 'left', backgroundColor: '#FFF', 
                              height: '31px',fontSize:'12px' }} >
                    Speed
                </div>
                <ScalarBox value={parseFloat(shakerSpeed)}  
                           setValue={setShakerSpeed}
                           step={1}
                           lowerBound={0}
                           upperBound={100} 
                />
            </div>
        </EffectItem>
    )

}

