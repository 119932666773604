import { useEffect, useState, useRef } from "react";
import Button from '@mui/material/Button'//'react-bootstrap/Button'
import ButtonGroup from '@mui/material/ButtonGroup'//'react-bootstrap/ButtonGroup'
import { DarkButton } from '../SpiceUI/MUI'

import { HiOutlineZoomOut } from "react-icons/hi";
import { HiOutlineZoomIn } from "react-icons/hi";
import { MdUndo } from "react-icons/md";

async function loadImageArray( layers )
{
    const promiseArray = []; // create an array for promises
    const imageArray = {}; // array for the images

    for( var layer of layers )
    {
        if( !layer.patch )
        {
            continue
        }

        promiseArray.push( new Promise( resolve => {
            
            const image = new Image();
            image.onload = function() {
                resolve()
            }
            image.src = 'data:image/png;base64, ' + layer.patch
            imageArray[layer.id] = image
        }))
    }

    await Promise.all(promiseArray);
    return imageArray
}

export default function Viewer( props )
{
    const layers = props.layers
    const imageArray = props.images
    const layersCfg = props.layersCfg
    const selected = props.selected
    const setSelected = props.setSelected
    const selectedIdx = props.selectedIdx
    const setSelectedIdx = props.setSelectedIdx
    const loading = props.loading

    const reEval = props.reEval
    const setReEval = props.setReEval

    const modalOpen = props.modalOpen

    const undo = props.undo

    const canvasRef = useRef(null)
    const canvasHeight = useRef(150)
    const canvasWidth = useRef(150)
    const layersLocal = useRef([])

    const selectedRef = useRef(selected)

    const validIds = useRef([])
    const images = useRef({})
    const states = useRef({})

    const clicked = useRef(false)
    const action = useRef(-1) // 0 - move, 1 - scale

    const globalScale = useRef(1.0)
    const globalOffset = useRef({ x:0.0, y:0.0 })

    const counter = useRef(0)

    const screen2canvas = ( x,y ) => {
        var rect = canvasRef.current.getBoundingClientRect();
        x = x - rect.left
        y = y - rect.top
        
        if( x < 0 || y < 0 || x >= canvasWidth.current || y >= canvasHeight.current )
        {
            return null;
        }

        return {x,y}
    }

    const pointInRegion = ( x,y, r, offset=0 ) => {
        if( x < r.x - offset || x >= r.x + offset + r.width )
        {
            return false 
        }

        if( y < r.y - offset || y >= r.y + offset + r.height )
        {
            return false
        }

        return true
    }

    const distanceToCenter = ( x,y,r ) => {
        let rx = r.x + r.width/2 
        let ry = r.y + r.height/2 

        return Math.pow( (x-rx),2 ) + Math.pow( (y-ry),2 )
    }

    const image2canvas = ( r ) => {
        let gs = globalScale.current
        let go = globalOffset.current

        let x = ( r.x ) * gs + go.x * gs
        let y = ( r.y ) * gs + go.y * gs
        let height = r.height * gs 
        let width = r.width * gs

        return {x,y,width,height}
    }

    const canvas2image = ( r ) => {
        let gs = globalScale.current
        let go = globalOffset.current

        let x = (r.x - go.x * gs ) / gs 
        let y = (r.y - go.y * gs ) / gs 
        let height = r.height / gs 
        let width = r.width / gs 

        return { x,y,width,height }
    }

    const determineAction = ( p, r ) => {

        let gs = globalScale.current
        let go = globalOffset.current

        let scale_rect0 = { x: (r.x) - 5, y: (r.y) - 5, width : 10, height : 10 }
        let scale_rect1 = { x: (r.x + r.width) - 5, y: (r.y) - 5, width : 10, height : 10 }
        let scale_rect2 = { x: (r.x) - 5, y: (r.y + r.height) - 5, width : 10, height : 10 }
        var scale_rect3 = { x: (r.x + r.width) - 5, y: (r.y + r.height) - 5, width : 10, height : 10 }

        if( pointInRegion( p.x, p.y, scale_rect0 ) )
        {
            return 1
        }
        else if( pointInRegion( p.x, p.y, scale_rect1 ) ) {
            return 2 
        }
        else if( pointInRegion( p.x, p.y, scale_rect2 ) ) {
            return 3
        }
        else if( pointInRegion( p.x, p.y, scale_rect3 ) ) {
            return 4
        }

        return 0
    }

    const updateCanvasSize = () => {
        const canvas = canvasRef.current
        let bounds = canvas.getBoundingClientRect()
        canvasHeight.current = bounds.height
        canvasWidth.current = bounds.width

        canvas.height = bounds.height 
        canvas.width = bounds.width
    }

    const calcFrameScale = () => {
        
        var height = -1
        var width = -1 

        for( var validId of validIds.current )
        {
            var imdata = images.current[ validId ] 

            if( !imdata )
            {
                continue
            }

            if( imdata.image.height > height )
            {
                height = imdata.image.height
            }

            if( imdata.image.width > width )
            {
                width = imdata.image.width
            }
        }

        if( height == -1 || width == -1 )
        {
            return null 
        }

        var scale = Math.min( canvasWidth.current/width, canvasHeight.current/height );
        var wdiff = Math.abs( canvasWidth.current - width*scale );
        var hdiff = Math.abs( canvasHeight.current - height*scale );


        let frame = {}
        frame['x'] = wdiff/2 
        frame['y'] = hdiff/2 
        frame['scale'] = scale 
        frame['h'] = canvasHeight.current
        frame['w'] = canvasWidth.current
    }

    const stepState = ( idx ) => {
        if( 'shaker' in states.current[idx] )
        {
            let x = states.current[idx]['shaker']['val']['x'] + states.current[idx]['shaker']['val']['dx']
            let y = states.current[idx]['shaker']['val']['y'] + states.current[idx]['shaker']['val']['dy']

            if( Math.abs(x) > states.current[idx]['shaker']['val']['boundx'] )
            {
                states.current[idx]['shaker']['val']['x'] = states.current[idx]['shaker']['val']['boundx'] * Math.sign(x)
                states.current[idx]['shaker']['val']['dx'] *= -1
            }
            else 
            {
                states.current[idx]['shaker']['val']['x'] = x
            }

            if( Math.abs(y) > states.current[idx]['shaker']['val']['boundy'] )
            {
                states.current[idx]['shaker']['val']['y'] = states.current[idx]['shaker']['val']['boundy'] * Math.sign(y)
                states.current[idx]['shaker']['val']['dy'] *= -1
            }
            else 
            {
                states.current[idx]['shaker']['val']['y'] = y
            }
        }

        if( 'rotation' in states.current[idx] )
        {
            states.current[idx]['rotation']['val']['angle'] += states.current[idx]['rotation']['val']['speed']
        }

        if( 'scale' in states.current[idx] )
        {
            let min_scale = states.current[idx]['scale']['val']['min_scale']
            let max_scale = states.current[idx]['scale']['val']['max_scale']
            let scale = states.current[idx]['scale']['val']['scale']
            let mode = states.current[idx]['scale']['val']['mode']
            let ds = states.current[idx]['scale']['val']['ds']


            if( mode == 'loop' )
            {
                if( scale < min_scale || scale > max_scale )
                {
                    ds *= -1
                }

                scale += ds 

                states.current[idx]['scale']['val']['scale'] = scale 
                states.current[idx]['scale']['val']['ds'] = ds
            }
            else if( mode == 'grow' )
            {
                if( scale < max_scale )
                {
                    scale += ds 
                    states.current[idx]['scale']['val']['scale'] = scale 
                }
                else 
                {
                    states.current[idx]['scale']['val']['scale'] = max_scale 
                }
            }
            else if( mode == 'shrink' )
            {
                if( scale > min_scale )
                {
                    scale += ds 
                    states.current[idx]['scale']['val']['scale'] = scale 
                }
                else 
                {
                    states.current[idx]['scale']['val']['scale'] = min_scale
                }
            }
        }
    }

    const redraw = () => {
        if( loading.current )
        {
            return
        }

        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d')

        ctx.clearRect(0, 0, canvasWidth.current, canvasHeight.current);

        updateCanvasSize()
        //calcFrameScale()

        ctx.rect(0,0,canvasWidth.current,canvasHeight.current)
        ctx.fillStyle = "white"
        ctx.fill()

        let gs = globalScale.current
        let go = globalOffset.current

        for( var validId of validIds.current )
        {
            let imdata = images.current[validId]
            let info = layersCfg.current[validId].info
            let location = layersCfg.current[validId].location 

            if( location == null )
            {
                continue
            }

            stepState( validId, imdata )

            let offset_x = 0 
            let offset_y = 0 

            let imageRect = { x : (location.x ) * gs + go.x * gs , 
                              y : (location.y ) * gs + go.y * gs,
                              width : location.width * gs,
                              height : location.height * gs }

            
            if( info.type == "image" )
            {

                if( 'shaker' in states.current[validId] )
                {
                    let s = location.width / states.current[validId]['shaker']['val']['image_width']
                    offset_x += states.current[validId]['shaker']['val']['x'] * gs * s
                    offset_y += states.current[validId]['shaker']['val']['y'] * gs * s
                }

                if( 'scale' in states.current[validId] )
                {
                    let s = states.current[validId]['scale']['val']['scale']

                    let w = imageRect.width * s 
                    let h = imageRect.height * s 
                    let x = imageRect.x + ( imageRect.width - w )/2 
                    let y = imageRect.y + ( imageRect.height - h )/2

                    imageRect = { x :x , y:y , width:w , height:h }
                }


                if( 'rotation' in states.current[validId] )
                {
                    ctx.save()
                    let angle = (states.current[validId]['rotation']['val']['angle'])
                    ctx.translate( imageRect.x + imageRect.width/2, imageRect.y + imageRect.height/2)
                    ctx.rotate( angle )

                    imdata.image.width = imageRect.width
                    imdata.image.height = imageRect.height

                    ctx.drawImage( imdata.image,
                        -1 * imageRect.width/2 + offset_x,
                        -1 * imageRect.height/2 + offset_y,
                        imageRect.width,
                        imageRect.height );

                    ctx.restore()
                }
                else 
                {
                    ctx.drawImage( imdata.image,
                        imageRect.x + offset_x,
                        imageRect.y + offset_y,
                        imageRect.width,
                        imageRect.height );

                } 
            }
            else 
            {
                switch( info.type )
                {
                    case "avatar":
                        ctx.fillStyle = "blue"
                        break;
                    case "emoticon":
                        ctx.fillStyle = "red"
                        break 
                    case "text":
                        ctx.fillStyle = "green"
                        break
                }

                ctx.fillRect( imageRect.x + offset_x,
                              imageRect.y + offset_y,
                              imageRect.width,
                              imageRect.height );
            
            }
        }

        if( validIds.current.includes( selectedRef.current ) )
        {
            let imdata = images.current[selectedRef.current]

            let location = layersCfg.current[selectedRef.current].location 

            if( location != null )
            {
                ctx.setLineDash([5,5])
                ctx.strokeStyle = "lightblue";
                //ctx.globalAlpha = 0.2;

                ctx.lineWidth = 2

                ctx.strokeRect( (location.x ) * gs + go.x * gs, 
                    (location.y ) * gs + go.y * gs, 
                    location.width * gs,
                    location.height * gs );
                ctx.globalAlpha = 1.0;

                ctx.lineWidth = 1
                ctx.setLineDash([1,0])

                ctx.fillStyle = "black"
                ctx.fillRect( ( location.x  ) * gs - 5 + go.x * gs, 
                              ( location.y ) * gs - 5 + go.y * gs, 
                              10,
                              10 );

                ctx.strokeRect( ( location.x ) * gs - 5 + go.x * gs, 
                                ( location.y ) * gs - 5 + go.y * gs, 
                                10,
                                10 )

                /*ctx.fillRect( ( location.x + location.width ) * gs - 5 + go.x * gs, 
                              ( location.y ) * gs - 5 + go.y * gs, 
                              10,
                              10 );

                ctx.strokeRect( ( location.x + location.width ) * gs - 5 + go.x * gs, 
                                ( location.y ) * gs - 5 + go.y * gs, 
                                10,
                                10 )
                
                ctx.fillRect( ( location.x ) * gs - 5 + go.x * gs, 
                              ( location.y + location.height ) * gs - 5 + go.y * gs, 
                              10,
                              10 );

                ctx.strokeRect( ( location.x ) * gs - 5 + go.x * gs, 
                                ( location.y + location.height ) * gs - 5 + go.y * gs, 
                                10,
                                10 )*/

                ctx.fillRect( ( location.x + location.width ) * gs - 5 + go.x * gs, 
                              ( location.y + location.height ) * gs - 5 + go.y * gs, 
                              10,
                              10 );
                ctx.strokeRect( ( location.x + location.width ) * gs - 5 + go.x * gs, 
                                ( location.y + location.height ) * gs - 5 + go.y * gs, 
                                10,
                                10 )
            }
        }

    }

    const reloadImages = () => {
        var oldIds = structuredClone( validIds.current )
        validIds.current = []
        for( var layerIdx in layers )
        {
            var layer = layers[layerIdx]

            if( layer.type == "image" )
            {
                let should_load = false 

                if( images.current[layer.id] == null )
                {
                    should_load = true 
                }
                else if( images.current[layer.id].filename != layer.filename || images.current[layer.id].hash != layer.hash )
                {
                    should_load = true
                }

                if( should_load )
                {
                    let height = imageArray[layer.id].height
                    let width = imageArray[layer.id].width 

                    let gs = globalScale.current 
                    let canvas_height = canvasHeight.current
                    let canvas_width = canvasWidth.current

                    let scale = 1.0

                    if( height / gs > canvas_height || width / gs > canvas_width )
                    {
                        scale = Math.min( canvasWidth.current/(width / gs), canvasHeight.current/(height / gs) );

                    }

                    let location = { x:0, y:0, 
                        width:width * scale / gs, 
                        height:height * scale / gs }
                    let info = { width:width, 
                        height:height,
                        aspect_ratio: width / height,
                        type: layer.type }

                    if( layersCfg.current[layer.id].location == null )
                    {
                        layersCfg.current[layer.id].location = location 
                    }

                    layersCfg.current[layer.id].info = info

                    let imdata = {}
                    imdata['image'] = imageArray[layer.id]
                    imdata['filename'] = layer.filename
                    imdata['hash'] = layer.hash

                    images.current[ layer.id ] = imdata
                }
            }
            else 
            {
                let should_load = false 

                if( images.current[layer.id] == null )
                {
                    should_load = true 
                }

                if( should_load )
                {
                    let height = 40.0 
                    let width = 40.0 
                    let gs = globalScale.current 

                    let location = { x:0, y:0, 
                        width:width / gs, 
                        height:height / gs }
                    let info = { width:width, 
                        height:height,
                        aspect_ratio: width / height,
                        type: layer.type }

                    if( layersCfg.current[layer.id].location == null )
                    {
                        layersCfg.current[layer.id].location = location 
                    }

                    layersCfg.current[layer.id].info = info
                }
            }

            var state = states.current[layer.id]

            if( state == null )
            {
                state = {}
            }

            if( 'shaker' in layersCfg.current[layer.id].effects )
            {
                let height = imageArray[layer.id].height
                let width = imageArray[layer.id].width 

                let bound = Math.min( height, width )/2

                let shakerCfg = layersCfg.current[layer.id].effects.shaker

                let widthCoef = shakerCfg.width / 100.0 
                let heightCoef = shakerCfg.height / 100.0
                let speedCoef = shakerCfg.speed / 1000.0

                let x = Math.floor(Math.random() * widthCoef * bound)
                let y = Math.floor(Math.random() * heightCoef * bound)

                if( 'shaker' in state )
                {
                    x = state['shaker']['val']['x']
                    y = state['shaker']['val']['y']
                }

                state['shaker'] = {}
                state['shaker']['cfg'] = shakerCfg 
                state['shaker']['val'] = { x:x,
                    y:y,
                    dx: speedCoef * bound,
                    dy: speedCoef * bound,
                    boundx: bound * widthCoef,
                    boundy: bound * heightCoef,
                    image_width: width 
                }
            }

            if( !('shaker' in layersCfg.current[layer.id].effects) )
            {
                delete state.shaker
            }

            if( 'rotation' in layersCfg.current[layer.id].effects )
            {
                let rotationCfg = layersCfg.current[layer.id].effects.rotation

                let speed = (rotationCfg.speed / 100.0) * Math.PI / 12
                
                let angle = 0 
                if( 'rotation' in state )
                {
                    angle = state['rotation']['val']['angle']
                }

                state['rotation'] = {}
                state['rotation']['cfg'] = rotationCfg 
                state['rotation']['val'] = { angle : angle, speed : speed }
            }

            if( !('rotation' in layersCfg.current[layer.id].effects) )
            {
                delete state.rotation
            }

            if( 'scale' in layersCfg.current[layer.id].effects )
            {
                let scaleCfg = layersCfg.current[layer.id].effects.scale

                let minScale = (scaleCfg.values[0]/50.0)
                let maxScale = (scaleCfg.values[1]/50.0)
                let speed = scaleCfg.speed /1000.0
                let mode = scaleCfg.mode 
                let ds = speed

                let scale = 1.0

                if( mode == 'shrink' )
                {
                    scale = maxScale
                    ds *= -1
                }
                else if( mode == 'grow' )
                {
                    scale = minScale 
                }
                else 
                {
                    scale = 1.0
                }

                if( 'scale' in state )
                {
                    scale = state['scale']['val']['scale']
                }

                scale = Math.min(Math.max(scale, minScale), maxScale)

                state['scale'] = {}
                state['scale']['cfg'] = scaleCfg
                state['scale']['val'] = {
                    min_scale: minScale,
                    max_scale: maxScale,
                    scale:scale,
                    speed:speed,
                    ds: ds,
                    mode : mode
                }
            }

            if( !('scale' in layersCfg.current[layer.id].effects) )
            {
                delete state.scale
            }

            states.current[ layer.id ] = state
            validIds.current.unshift( layer.id )
        }

        // Clean up unused buffers
        for( var id of oldIds )
        {
            if( !validIds.current.includes(id) )
            {
                delete images.current[id]
                delete states.current[id]
            }
        }

        loading.current = false

    }

    const resizeListener = (e) => {
        updateCanvasSize()
        calcFrameScale()
    }

    const scaleLocation = ( location, scale ) => {
        let x = location.x * scale 
        let y = location.y * scale 
        let height = location.height * scale 
        let width = location.width * scale 

        return {x,y,height,width}
    }

    const clickSelection = ( p ) => {
        let selected_idx = -1
        let selected_id = null

        let idx = 0

        let items = []

        for( let layer of layersLocal.current )
        {
            let layer_id = layer.id
            let location = layersCfg.current[layer_id].location
            let imrect = image2canvas( location )

            if( pointInRegion( p.x, p.y, imrect, 5 ) )
            {
                items.push( {idx, layer_id, imrect} )
            }

            idx += 1
        }

        let min_distance = 1e10;

        for( let item of items )
        {
            let d = distanceToCenter( p.x, p.y, item.imrect )

            if( d < min_distance )
            {
                selected_idx = item.idx;
                selected_id = item.layer_id

                min_distance = d;
            }
        }

        setSelected( selected_id )
        setSelectedIdx( selected_idx )

        return { idx:selected_idx, id:selected_id }
    }

    const mouseDownListener = ( e ) => {
        if( modalOpen.current )
        {
            return
        }


        let p = screen2canvas( e.clientX, e.clientY )

        if( p == null ) {
            return
        }

        let selection = clickSelection(p)

        if( selection.idx == -1 )
        {
            clicked.current = true 
            action.current = 5
            return
        }

        if( !validIds.current.includes( selectedRef.current) ) {
            return
        }

        var imdata = images.current[selectedRef.current]
        let location = layersCfg.current[ selectedRef.current ].location


        //let p = screen2canvas( e.clientX, e.clientY )

        if( p == null )
        {
            return
        }

        var imrect = image2canvas( location )

        if( pointInRegion( p.x,p.y, imrect, 5 ) )
        {
            clicked.current = true
            action.current = determineAction( p, imrect )
        }
    }

    const mouseMoveListener = ( e ) => {
        if( modalOpen.current )
        {
            return
        }

        if( clicked.current )
        {
            if( action.current == 5 )
            {
                globalOffset.current.x += e.movementX / globalScale.current
                globalOffset.current.y += e.movementY / globalScale.current

                return
            }

            let p = screen2canvas( e.clientX, e.clientY ) 

            if( action.current == 0 ) {
                layersCfg.current[selectedRef.current].location.x += e.movementX / globalScale.current 
                layersCfg.current[selectedRef.current].location.y += e.movementY / globalScale.current
            }
            else if( action.current == 1 ) {
                let far = layersCfg.current[selectedRef.current].fixed_aspect_ratio 
                far = ( far == null ) ? true : far

                if( far )
                {
                    let gs = globalScale.current
                    let move = -1 * ((e.movementX/gs) + (e.movementY/gs)) * 0.5
                    let location = layersCfg.current[selectedRef.current].location

                    let height = layersCfg.current[selectedRef.current].location.height
                    let width = layersCfg.current[selectedRef.current].location.width 

                    let hscale = (height + move) / height 
                    let wscale = (width + move) / width 

                    let scale = ( hscale + wscale ) * 0.5

                    let nheight = height * scale 
                    let nwidget = width * scale 

                    location.x = ( location.x + location.width - nwidget )
                    location.y = ( location.y + location.height - nheight )
                    location.width = nwidget
                    location.height = nheight  

                    layersCfg.current[selectedRef.current].location = location
                }
                else 
                {
                    let gs = globalScale.current
                    let location = layersCfg.current[selectedRef.current].location

                    location.x += e.movementX/gs 
                    location.width -= e.movementX/gs 
                    location.y += e.movementY/gs
                    location.height -= e.movementY/gs 

                    layersCfg.current[selectedRef.current].location = location

                }

                /*if( far )
                {
                    let move = -1 * (e.movementX + e.movementY) * 0.5 

                    imrect.x -= move 
                    imrect.width += move

                    imrect.y -= move 
                    imrect.height += move 

                }
                else 
                {
                    imrect.x += e.movementX 
                    imrect.width -= e.movementX
                    imrect.y += e.movementY
                    imrect.height -= e.movementY 
                }*/
            } 

            /*else if( action.current == 2 ) {
                let far = layersCfg.current[selectedRef.current].fixed_aspect_ratio 
                far = ( far == null ) ? true : far 

                if( far )
                {
                    let move = (e.movementX + e.movementY) * 0.5 
                    imrect.width += move 

                    imrect.y -= move 
                    imrect.height += move
                    //imrect.height += move 

                }
                else 
                {
                    imrect.width += e.movementX
                    imrect.y += e.movementY
                    imrect.height -= e.movementY 

                }

            }
            else if( action.current == 3 ) {
                let far = layersCfg.current[selectedRef.current].fixed_aspect_ratio 
                far = ( far == null ) ? true : far 

                if( far )
                {
                    let move = (e.movementX + e.movementY) * 0.5 
                    imrect.width += move 

                    imrect.y += move 
                    imrect.height -= move
                    //imrect.height += move 

                }
                else 
                {
                    imrect.height += e.movementY
                    imrect.x += e.movementX
                    imrect.width -= e.movementX

                }

            }*/

            else if( action.current == 4 ) // Scale
            {
                let far = layersCfg.current[selectedRef.current].fixed_aspect_ratio 
                far = ( far == null ) ? true : far

                if( far )
                {
                    let gs = globalScale.current
                    let move = ((e.movementX/gs) + (e.movementY/gs)) * 0.5
                    let location = layersCfg.current[selectedRef.current].location

                    let height = layersCfg.current[selectedRef.current].location.height
                    let width = layersCfg.current[selectedRef.current].location.width 

                    let hscale = (height + move) / height 
                    let wscale = (width + move) / width 

                    let scale = ( hscale + wscale ) * 0.5

                    let nheight = height * scale 
                    let nwidget = width * scale 

                    //location.x += (width - width * scale )
                    //location.y += (height - height * scale)
                    location.width = nwidget
                    location.height = nheight  

                    layersCfg.current[selectedRef.current].location = location
                }
                else 
                {
                    let gs = globalScale.current
                    let location = layersCfg.current[selectedRef.current].location

                    location.width += e.movementX/gs 
                    location.height += e.movementY/gs 

                    layersCfg.current[selectedRef.current].location = location
                }
                /*let far = layersCfg.current[selectedRef.current].fixed_aspect_ratio 
                far = ( far == null ) ? true : far 

                if( far )
                {
                    let ratio = imrect.width / imrect.height 
                    let move = (e.movementX + e.movementY) * 0.5 
                    imrect.height += move / ratio
                    imrect.width += move * ratio
                }
                else 
                {
                    imrect.height += e.movementY 
                    imrect.width += e.movementX
                }*/
            }

            //layersCfg.current[selectedRef.current].location = canvas2image( imrect )
        }
    }

    const mouseUpListener = ( e ) => {
        if( modalOpen.current )
        {
            return
        }

        clicked.current = false

        /* Re-evaluating to see if any change has been made */
        counter.current += 1
        setReEval( counter.current )
    }

    const keyDownListener = ( e ) => {

        if( ( e.ctrlKey || e.metaKey ) && e.key == "z" )
        {
            undo()
            e.preventDefault()
        }
    }

    useEffect( () => {
        reloadImages()
        const timer = setInterval( () => { redraw() },1000/30 )

        window.addEventListener("resize", resizeListener )
        window.addEventListener("mousedown", mouseDownListener )
        window.addEventListener("mousemove", mouseMoveListener )
        window.addEventListener("mouseup", mouseUpListener )
        window.addEventListener("keydown", keyDownListener )

        return () => {
            clearInterval(timer)
            window.removeEventListener("resize", resizeListener )
            window.removeEventListener("mousedown", mouseDownListener )
            window.removeEventListener("mousemove", mouseMoveListener )
            window.removeEventListener("mouseup", mouseUpListener )
            window.removeEventListener("keydown", keyDownListener )
        }
    },[])

    useEffect( () => {
        layersLocal.current = structuredClone(layers)
        reloadImages()
    },[imageArray])

    useEffect( () => {
        selectedRef.current = selected
    },[selected])

    const zoomIn = () =>{

        globalScale.current += 0.08
    }

    const zoomOut = () => {
        globalScale.current -= 0.08
    }

    return (
        <div style={{ width:'100%', height:'100%' }}>
            <div style={{ height:'100%', width:'100%', position:'relative'}}>
                <canvas ref={canvasRef} 
                        height={canvasHeight.current} 
                        width={canvasWidth.current} 
                        style={{ height:"100%", width:"100%", position:'absolute', top:'0', left:'0' }} 
                />
                <ButtonGroup style={{ position :'absolute', bottom:'0', right:'0' }}>
                    <DarkButton variant="contained" size="small" onClick={undo}>
                        <MdUndo style={{ fontSize:'25px' }}/>
                    </DarkButton>
                    <DarkButton onClick={zoomOut} variant="contained" size="small">
                        <HiOutlineZoomOut style={{ fontSize:'25px' }} />
                    </DarkButton>
                    <DarkButton onClick={zoomIn} variant="contained" size="small">
                        <HiOutlineZoomIn style={{ fontSize:'25px'}}/>
                    </DarkButton>
                </ButtonGroup>
            </div>
        </div>
    )
}

