import Container from 'react-bootstrap/Container'
import LoginBanner from '../Components/Landing/Login'
import ScaledImg from '../Components/ScaledImg'

export default function Landing( props )
{
    return (
        <div style={{ height:'100vh', width:'100%' }}>
            <Container style={{ height:'100%', maxWidth:'1200px' }}>
                <div className="d-flex justify-content-center align-items-end" 
                     style={{ width:'100%', height:'120px' }}>
                    <img src={'/images/logo-motion-0205_1.gif'} 
                         style={{ width:'auto', height:'80%'}}  
                    />
                </div>
                <div className="d-flex justify-content-center align-items-end" 
                     style={{ width:'100%', height:'250px' }}>
                    <LoginBanner />
                </div>
                <div style={{ width:'100%', height:'calc(100% - 370px)' }}>
                    <ScaledImg imageSrc="images/avatar-home.png" />
                </div>
            </Container>
        </div>
    )
}
