import { useState, useEffect, useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from '@mui/material/Button'
import Form from 'react-bootstrap/Form'

import { stringToHash } from '../Misc/FileTools'

import { LiaPepperHotSolid } from "react-icons/lia";

export function ItemButton( props )
{
    const name = props.name
    const selected = props.selected 
    const setSelected = props.setSelected
    const disabled = ( props.disabled == null ) ? false : props.disabled
    
    const [ isHover, setIsHover ] = useState( false )

    if( disabled )
    {
        return (
            <div className="d-flex justify-content-center align-items-center"
                 style={{ height:'60px', width:'100%',
                          border:'',
                          borderRadius:'5px',
                          backgroundColor:'',
                          padding:'10px',
                          color:'grey',
                          ...props.style }}
            >
                {props.children}
            </div>
        )
    }

    return (
        <div className="d-flex justify-content-center align-items-center"
             style={{ height:'60px', width:'100%',
                      border:(isHover) ? '0.2px solid #DADADA' : '',
                      borderRadius:'5px',
                      backgroundColor:(selected == name) ? '#DADADA' : '',
                      padding:'10px',
                      ...props.style }}
             onMouseEnter={e => setIsHover(true)}
             onMouseLeave={e => setIsHover(false)}
             onClick={ e => setSelected(name)}
        >
            {props.children}
        </div>
    )
}

export function ModalBody( props )
{
    const addCloseModal = props.addCloseModal
    const closeModal = props.closeModal 
    const accountType = props.accountType
    
    const [ selected, setSelected ] = useState("")

    const file = useRef(null)
    //const [ file, setFile ] = useState(null)
    const [ fileValue, setFileValue ] = useState("")

    const fileSetter = ( v,f ) =>
    {
        setFileValue( v )
        file.current = f

        var fr = new FileReader();

        fr.onload = function() {
            let content =  btoa(fr.result)

            var data = {}
            data.content = content
            data.filename = file.current.name
            data.hash = stringToHash(content)

            //uploadCloseModal( data )
            file.current = data
        }

        fr.readAsBinaryString(file.current);
    }

    const upload = () => {
        if( selected == "" )
        {
            return
        }

        if( file.current == null && selected=="image" )
        {
            closeModal()
            return
        } 

        addCloseModal( selected, file.current )
    }

    return (
        <>
            <Modal.Body>
                <div style={{ width:'100%', height:'290px', padding:'10px'}}>
                    <ItemButton name="image" selected={selected} setSelected={setSelected}
                                style={{ marginBottom:'10px' }}>
                        <Form.Control type="file" value={fileValue} 
                              onChange={(e) => fileSetter( e.target.value, e.target.files[0])}
                              size="sm"
                              style={{ maxWidth:'250px'}}
                        />
                    </ItemButton>

                    <ItemButton name="avatar" selected={selected} setSelected={setSelected}
                                style={{ marginBottom:'10px' }} disabled={accountType=='mild'}>
                        Avatar <LiaPepperHotSolid style={{ marginLeft:'10px'}} />
                    </ItemButton>

                    <ItemButton name="emoticon" selected={selected} setSelected={setSelected}
                                style={{ marginBottom:'10px' }} disabled={accountType=='mild'}>
                        Emoticon <LiaPepperHotSolid style={{ marginLeft:'10px'}}/>
                    </ItemButton> 
                    <ItemButton name="text" selected={selected} setSelected={setSelected}
                                style={{ marginBottom:'10px' }} disabled={accountType=='mild'}>
                        Text <LiaPepperHotSolid style={{ marginLeft:'10px'}}/>
                    </ItemButton>
                </div>
            </Modal.Body>
            <Modal.Footer style={{ border:'none'}}>
                <Button variant="contained" onClick={closeModal} 
                        style={{ marginRight:'5px', textTransform:'none' }} 
                        size="small">Close</Button>
                <Button variant="contained" onClick={upload} style={{ textTransform:'none' }}
                        size="small">Add</Button>
            </Modal.Footer>
        </>
    )
}

export default function AddLayerModal( props )
{
    const showModal = props.showModal 
    const closeModal = props.closeModal 
    const addCloseModal = props.addCloseModal 
    const accountType = props.accountType

    return (
        <Modal show={showModal} onHide={closeModal}>
            <Modal.Header closeButton style={{ border:'none' }}>
                <Modal.Title style={{ fontSize:'20px'}}>Add Layer</Modal.Title>
            </Modal.Header>
            { showModal &&
            <ModalBody addCloseModal={addCloseModal} closeModal={closeModal} accountType={accountType} />
            }
        </Modal>
    )
}

