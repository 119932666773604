import html2canvas from 'html2canvas'

export async function componentCapture( ref, scale=2 )
{   
    let cap = await html2canvas(ref.current, {backgroundColor:null, scale:scale})
    const capData = cap.toDataURL('image/png').split(",")[1]
    
    let out = {}
    out.img = capData 
    out.scale = scale

    return out
}

